import { useIntl } from 'react-intl';
import { Participant, Room } from 'twilio-video';

import { usePatientProfile } from 'src/hooks/usePatientProfile';

import { SelectedDevice } from './video-call-types';
import messages from './video-call.messages';
import { VideoCallEndScreen } from './VideoCallEndScreen';
import { VideoCallParticipant } from './VideoCallParticipant';

import styles from './PatientVideoCall.module.scss';

type Props = {
  room: Room;
  participants: Participant[];
  stopVideo: boolean;
  muteAudio: boolean;
  isCallOver: boolean;
  device?: SelectedDevice;
  patientId: string;
};

export function VideoCallScreen({
  room,
  participants,
  stopVideo,
  muteAudio,
  isCallOver,
  device,
  patientId,
}: Props) {
  const { patient } = usePatientProfile(patientId);
  const intl = useIntl();

  const { name } = patient || {};
  const waitingLabel =
    participants?.length < 1 ? intl.formatMessage(messages.waiting, { name }) : '';
  const stopVideoLabel = intl.formatMessage(messages.cameraOff);

  if (isCallOver) {
    return <VideoCallEndScreen patientId={patientId} />;
  }

  return (
    <div className={styles.videoCall__videoScreenWrapper}>
      {waitingLabel}
      <div className={styles.videoCall__providerScreen}>
        <VideoCallParticipant
          key={room?.localParticipant.sid}
          participant={room?.localParticipant}
          muteAudio={muteAudio}
          stopVideo={stopVideo}
          device={device}
        />
        {stopVideo && <span>{stopVideoLabel}</span>}
      </div>
      <div className={styles.VideoCall__patientScreen}>
        {participants?.map((p) => <VideoCallParticipant key={p.sid} participant={p} />)}
      </div>
    </div>
  );
}
