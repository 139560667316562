import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { parseClinicsData } from 'src/domain/Clinic';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useUserClinics } from 'src/hooks/useUserClinics';

import { ActionComplete } from '../ManageUsers/Form/ActionComplete';
import { InviteProvider } from '../ManageUsers/Form/InviteProviderPage';

export function InviteProviderPage() {
  const [step, setStep] = useState<'form' | 'created'>('form');
  const [actionCompleteState, setActionCompleteState] = useState<{
    formEmail: string;
    formFirstName: string;
    formLastName: string;
    clinicLabel: string;
    roleLabel: string;
  }>();
  const { toPreviousPage, toClinicProvidersPage } = useAppNavigate();
  const { clinicId } = useParams();
  const { clinics } = useUserClinics();
  const parsedClinics = parseClinicsData(clinics);

  const steps = {
    'form': <InviteProvider clinics={parsedClinics} onCreate={onCreate} goBack={toPreviousPage} />,
    'created': (
      <ActionComplete
        isUpdatedUser={false}
        isDeletedUser={false}
        state={actionCompleteState!}
        goBack={() => toClinicProvidersPage(clinicId!)}
      />
    ),
  };

  return <>{steps[step]}</>;

  function onCreate(state: {
    formEmail: string;
    formFirstName: string;
    formLastName: string;
    clinicLabel: string;
    roleLabel: string;
  }) {
    setStep('created');
    setActionCompleteState(state);
  }
}
