import 'chartjs-plugin-style';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useThrottledWindowSize } from 'src/hooks/useWindowSize';
import globalMessages from 'src/messages/global.messages';
import { breakpoints } from 'src/styles/theme/breakpoints';

import tooltips from './scale-data-line-chart-tooltips';

Chart.defaults.font.family = "'GT-America-Standard-Regular', 'PT Sans', sans-serif";
Chart.unregister(ChartDataLabels);

type Props = {
  id: string;
  data: {}[];
  units: string;
};

export function ScaleDataLineChart({ id, data, units }: Props) {
  const intl = useIntl();

  const canvasRef = useRef<any>(null);
  const chartRef = useRef<any>(null);

  const [width] = useThrottledWindowSize();

  const isMobile = width < breakpoints.tablet;

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [' '],
        datasets: [
          {
            label: 'Month 1',
            fill: false,
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointHoverBackgroundColor: '#B8D64D',
            pointHoverBorderColor: '#B8D64D',
            pointRadius: 5,
            borderColor: '#B8D64D',
            borderWidth: isMobile ? 1 : 3,
            data,
          },
        ],
      },
      options: {
        responsive: true,
        layout: {
          padding: {
            left: 0,
            right: isMobile ? 26 : 0,
            top: 15,
            bottom: 0,
          },
        },
        scales: {
          x: {
            type: 'timeseries',
            display: true,
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM dd',
              },
            },
            title: {
              display: true,
              font: {
                family: 'GT-America-Standard-Medium',
                size: isMobile ? 6 : 13,
              },
              padding: isMobile ? 5 : 20,
            },
            ticks: {
              maxRotation: 45,
              minRotation: 45,
              autoSkip: true,
              source: 'data',
              display: true,

              font: {
                size: isMobile ? 6 : 13,
              },
              callback(value) {
                // @ts-ignore
                const dateTime: string = this._adapter.format(value, 'MMM dd');
                const [month, day] = String(dateTime).split(' ');
                const monthMessage = (globalMessages as any)[month];
                const msg = monthMessage ? intl.formatMessage(monthMessage) : monthMessage;

                return day ? `${msg} ${day}` : `${msg}`;
              },
            },
            grid: {
              display: false,
            },
            border: {
              display: true,
            },
          },
          y: {
            beginAtZero: false,
            title: {
              display: true,
              font: {
                family: 'GT-America-Standard-Medium',
                size: isMobile ? 6 : 13,
              },
            },
            ticks: {
              display: true,
              stepSize: 0.5,
              font: {
                size: isMobile ? 6 : 13,
              },
              callback: (value) => `${value} ${units}`,
            },
            grid: {
              display: true,
            },
            border: {
              display: true,
            },
          },
        },
        plugins: {
          tooltip: tooltips(units),
          legend: {
            display: false,
          },
        },
      },
    });
  }, [data, isMobile, units, intl]);

  return <canvas id={id} ref={canvasRef} />;
}
