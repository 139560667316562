import { IntlShape } from 'react-intl';

import globalMessages from 'src/messages/global.messages';

export function validate(intl: IntlShape) {
  return (values: { email?: string }) => {
    const errors: {
      email?: string;
    } = {};

    if (!values.email) {
      errors.email = intl.formatMessage(globalMessages.required);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = intl.formatMessage(globalMessages.notValidEmail);
    }

    return errors;
  };
}
