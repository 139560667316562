import { isNotNull } from '@allurion/utils';
import { useContext, createContext } from 'react';

import { User } from 'src/domain/User';
import { mode } from 'src/utils/env';
import env from 'src/utils/env';

class Analytics {
  currentUser: User | null = null;

  private getSubdomain() {
    return window.location.hostname.split('.')[0];
  }

  trackUser(user?: User) {
    if (!user || !user.username) {
      return;
    }

    // no need to setup the current user again if it didn't change
    if (isNotNull(this.currentUser) && this.currentUser.username === user.username) {
      return;
    }

    this.currentUser = user;

    if (mode === 'dev') {
      // eslint-disable-next-line no-console
      console.log('trackUser', user);
    }

    window?.gtag?.('config', env.GTM_ID, {
      'user_id': user.username,
      al_provider_id: user.providerId,
      al_role_id: user.roleId,
      al_subdomain: this.getSubdomain(),
    });
  }

  trackEvent(event: string, payload?: object) {
    if (mode === 'dev') {
      const finalPayload = {
        ...payload,
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      };

      // eslint-disable-next-line no-console
      console.log('trackEvent', event, finalPayload);
    }

    // Google Analytics
    window?.gtag?.('event', event, {
      ...payload,
      page_location: window.location,
      page_title: document.title,
      al_subdomain: this.getSubdomain(),
    });
  }

  getElementPath = (element: Element | null) => {
    const path = [];
    let node = element;

    while (node) {
      path.unshift(node.nodeName);
      node = node.parentNode as Element;
    }

    return path.join(' > ');
  };
}

const AnalyticsContext = createContext<Analytics | null>(null);

export const useTrackEvent = () => {
  const analytics = useContext(AnalyticsContext);

  return {
    trackCurrentUser(user: User) {
      analytics?.trackUser(user);
    },
    trackLogin({ providerId }: { providerId?: string | number }) {
      analytics?.trackEvent('login', { al_provider_id: providerId });
    },
    trackLogout({ providerId }: { providerId?: string | number }) {
      analytics?.trackEvent('logout', { al_provider_id: providerId });
    },
    trackPageView() {
      analytics?.trackEvent('page_view');
    },
    trackClick: (
      element: Element | null,
      { label, patientId }: { label: string; patientId?: string }
    ) => {
      const elementPath = analytics?.getElementPath(element);

      analytics?.trackEvent('click', {
        elementPath,
        label: label,
        al_patient_id: patientId,
      });
    },
    trackSorting: (payload: { sortBy: string; label: string }) => {
      analytics?.trackEvent('sort', payload);
    },
    trackFormSuccess: (formName: string, payload?: object) => {
      analytics?.trackEvent('form_success', { formName, ...payload });
    },
    trackFormError: (formName: string, payload?: object) => {
      analytics?.trackEvent('form_error', { formName, ...payload });
    },
    trackLoadFailure: (payload?: object) => {
      analytics?.trackEvent('load_failure', payload);
    },
    trackChatMessageSent: ({
      patientId,
      messageIndex,
      conversationId,
    }: {
      patientId: string;
      messageIndex?: number;
      conversationId: string;
    }) => {
      analytics?.trackEvent('message_sent', {
        al_patient_id: patientId,
        al_message_index: messageIndex,
        al_conversations_id: conversationId,
      });
    },
    trackSaveNote({
      patientId,
      category,
    }: {
      patientId: string;
      providerId?: string | number;
      category: number;
    }) {
      analytics?.trackEvent('note_saved', {
        al_patient_id: patientId,
        al_note_category: category.toString(),
      });
    },
    trackVideoStart({
      patientId,
      providerId,
    }: {
      patientId: string;
      providerId?: string | number;
    }) {
      analytics?.trackEvent('video_started', {
        al_patient_id: patientId,
        al_provider_id: providerId,
      });
    },
    trackTwilioChatFailure: (payload?: object) => {
      analytics?.trackEvent('twilio_chat_failure', payload);
    },
    trackTwilioFailedMessage: (payload?: object) => {
      analytics?.trackEvent('twilio_failed_message', payload);
    },
    trackTwilioFailedVideoCall: (payload?: object) => {
      analytics?.trackEvent('twilio_failed_video_call', payload);
    },
    trackEmptyState: (payload?: { label: string }) => {
      analytics?.trackEvent('empty_state', payload);
    },
  };
};

type Props = {
  children: React.ReactNode;
};

export function AnalyticsProvider({ children }: Props) {
  const analytics = new Analytics();

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}
