/**
 *
 * ValidateEmail validation
 *
 * */
import { FormattedMessage } from 'react-intl';

import globalMessages from 'src/messages/global.messages';

import messages from './validate-email-code-messages';

export const validate = (values: { code?: string; password?: string }) => {
  const errors: {
    code?: JSX.Element;
    password?: JSX.Element;
  } = {};

  if (!values.code) {
    errors.code = <FormattedMessage id={globalMessages.required.id} />;
  }

  if (!values.password) {
    errors.password = <FormattedMessage id={messages.newPasssowrdPrompt.id} />;
  } else if (values.password.length < 8) {
    errors.password = <FormattedMessage id={globalMessages.minChars.id} />;
  } else if (!/[a-z]/.test(values.password)) {
    errors.password = <FormattedMessage id={globalMessages.oneLower.id} />;
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = <FormattedMessage id={globalMessages.oneCapital.id} />;
  } else if (!/[0-9]/.test(values.password)) {
    errors.password = <FormattedMessage id={globalMessages.oneNumber.id} />;
  } else if (!/[!@#$%^&*()_+{}:;'"/\\]/.test(values.password)) {
    errors.password = <FormattedMessage id={globalMessages.oneSpecialChar.id} />;
  }

  return errors;
};
