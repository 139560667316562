export const getReadableFileSize = (size: number): string => {
  let i = 0;
  let s = size;
  const byteUnits = ['kB', 'MB', 'GB'];

  while (s > 1024) {
    i += 1;
    s /= 1024;
  }

  return `${Math.max(s, 0.1).toFixed(1)} ${byteUnits[i - 1]}`;
};

export const encodeFile = (file: File | null) => {
  const formData = new FormData();
  const { type = '' } = file || {};

  formData.append(type, file as any);

  return formData;
};

export const readURL = (file: File): Promise<string> =>
  new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = (e) => res(e.target?.result as string);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });

export const imageTypes = [
  'jpeg',
  'png',
  'gif',
  'bmp',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
];
