import { useConfirmationDialog } from '@allurion/ui';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { sendArticleNotification } from 'src/api/PatientApi';
import { Article } from 'src/domain/patient/Patients';
import { useLocale } from 'src/hooks/useLocale';
import globalMessages from 'src/messages/global.messages';
import { Logger } from 'src/services/Logger';
import { CircularProgress } from 'src/ui/CircularProgress';

type Props = {
  patientID: string;
  article: Article;
  reload: () => Promise<void>;
};

export function SendArticleButton({ patientID, article, reload }: Props) {
  const { locale } = useLocale();
  const intl = useIntl();
  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const { askConfirmationPromise, ConfirmationDialog } = useConfirmationDialog();

  const confirmMessage = article.sentDate
    ? intl.formatMessage({
        id: 'content-library.articles-table.resend-confirm-message',
        defaultMessage:
          'This patient already received this article. Confirm sending this article again?',
      })
    : intl.formatMessage({
        id: 'content-library.articles-table.send-confirm-message',
        defaultMessage: 'Confirm sending this article?',
      });

  const buttonLabel = article.sentDate
    ? intl.formatMessage({
        id: 'content-library.articles-table.resend',
        defaultMessage: 'Resend',
      })
    : intl.formatMessage({
        id: 'content-library.articles-table.send',
        defaultMessage: 'Send',
      });

  const onClick = async () => {
    const shouldSendNotification = await askConfirmationPromise({
      title: confirmMessage,
      confirmText: intl.formatMessage(globalMessages.confirm),
      cancelText: intl.formatMessage(globalMessages.cancel),
    });

    if (!shouldSendNotification) return;
    setIsSendingNotification(true);

    try {
      await sendArticleNotification(patientID, article.id, article.collectionId, locale);
      await reload();
    } catch (error) {
      Logger.captureException(error);
    }

    setIsSendingNotification(false);
  };

  return (
    <>
      <ConfirmationDialog />
      <TrackedButton
        label={buttonLabel}
        variant="secondary"
        size="xs"
        onClick={onClick}
        leftIcon={
          isSendingNotification && (
            <CircularProgress color="inherit" size="16px" thickness="1.5px" />
          )
        }
        trackLabel="send-article"
      />
    </>
  );
}
