import { PageHeader } from '@allurion/ui';
import { FormikProvider } from 'formik';
import { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { Seo } from 'src/components/Seo';
import { AllurionRadioButtons } from 'src/components/ui/AllurionRadioButtons';
import AllurionSwitch from 'src/components/ui/AllurionSwitch';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';
import { Loader } from 'src/components/ui/Loader';
import { toastError, toastSuccess } from 'src/components/ui/toasts';
import { ClinicSettingsFeatures } from 'src/domain/settings';
import { Unit } from 'src/domain/User';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useUserClinics } from 'src/hooks/useUserClinics';
import { useUserClinicsSettings } from 'src/hooks/useUserClinicsSettings';
import { Logger } from 'src/services/Logger';

import messages from './ProfilePage.messages';
import { Container, InnerContainer } from './shared-page-elements';

export default function ProfilePage() {
  const intl = useIntl();
  const { clinics, isLoading: isLoadingClinics } = useUserClinics();
  const { hasAccessToFeature } = useUserClinicsSettings();
  const { user, unitsPreference, update } = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState<string>(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [units, setUnits] = useState(unitsPreference);
  const [hasChanges, setHasChanges] = useState(false);

  const clinicHasEmailNotifications = hasAccessToFeature(ClinicSettingsFeatures.EmailNotifications);

  const clinicHasMessages = hasAccessToFeature(ClinicSettingsFeatures.Messages);

  const [notificationsEnabled, setNotificationsEnabled] = useState(
    user?.notificationsEnabled ?? false
  );
  const [notificationsFrequency, setNotificationsFrequency] = useState(
    user?.notificationsFrequency
  );

  const showEmailNotificationSettings = clinicHasMessages && clinicHasEmailNotifications;

  const { toClinicPage, toPreviousPage } = useAppNavigate();
  const submitForm = async () => {
    try {
      setIsLoading(true);

      await update({
        firstName,
        lastName,
        unitsPreference: units,
        notificationsEnabled,
        notificationsFrequency: notificationsEnabled ? notificationsFrequency : undefined,
      });
      toastSuccess(intl.formatMessage(messages.updateProfileSuccess));
      setIsLoading(false);

      toClinicPage();
    } catch (error) {
      Logger.captureException(error);
      toastError(intl.formatMessage(messages.updateProfileFailure));
      setIsLoading(false);
    }
  };

  const changeFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setFirstName(value);
    setHasChanges(true);
  };

  const changeLastName = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setLastName(value);
    setHasChanges(true);
  };

  const changeUnits = ({ value }: { value: Unit }) => {
    setUnits(value);
    setHasChanges(true);
  };

  const changeMessageNotifications = (event: ChangeEvent<HTMLInputElement>) => {
    setNotificationsEnabled(event.target.checked);
    setHasChanges(true);
  };

  const changeNotificationsFrequency = ({ value }: { value: string }) => {
    setNotificationsFrequency(value);
    setHasChanges(true);
  };

  const weightUnitOptions: { label: string; value: string }[] = [
    { label: 'Kg', value: 'kg' },
    { label: 'Lb', value: 'lb' },
  ];

  const messageNotificationsOptions = [
    {
      label: intl.formatMessage(messages.messageNotificationsOnceADay),
      description: intl.formatMessage(messages.messageNotificationsOnceADayDescription),
      value: 'once_day',
    },
    { label: intl.formatMessage(messages.messageNotificationsEveryTime), value: 'everytime' },
  ];

  // TODO: integrate Formik here, remove mocked functions
  const formikValue = {
    getFieldProps: () => {},
    getFieldMeta: () => {},
    getFieldHelpers: () => {},
    registerField: () => {},
  };

  return (
    <div style={{ width: '100%' }}>
      <Seo title="Profile" />
      <PageHeader
        title={intl.formatMessage(messages.title)}
        onNavButtonClick={() => toPreviousPage()}
      />
      <Container>
        <InnerContainer>
          <FormikProvider value={formikValue as any}>
            <Wrapper>
              <div>
                <AllurionTextField
                  label={`${intl.formatMessage(messages.firstName)}:`}
                  value={firstName}
                  onChange={changeFirstName}
                  isRequired
                />
              </div>
              <div>
                <AllurionTextField
                  label={`${intl.formatMessage(messages.lastName)}:`}
                  value={lastName}
                  onChange={changeLastName}
                  isRequired
                />
              </div>
            </Wrapper>
            <Item data-cy="email">
              <ValueLabel>{`${intl.formatMessage(messages.email)}:`}</ValueLabel>
              <Value>{user?.email}</Value>
            </Item>
            <Item data-cy="clinic">
              <ValueLabel>{`${intl.formatMessage(messages.clinic)}:`}</ValueLabel>
              {clinics.map((clinic) => (
                <Value key={clinic.ID}>{clinic?.post_title}</Value>
              ))}
            </Item>
            <Item data-cy="units">
              <ValueLabel style={{ marginBottom: 15 }}>
                {`${intl.formatMessage(messages.units)}:`}
              </ValueLabel>
              <AllurionRadioButtons
                onSelect={changeUnits}
                defaultValue={units}
                options={weightUnitOptions}
                row
              />
            </Item>

            {showEmailNotificationSettings && (
              <Item data-cy="message-notifications">
                <ValueLabel>{`${intl.formatMessage(messages.messageNotifications)}:`}</ValueLabel>
                <p>{`${intl.formatMessage(messages.messageNotificationsDescription)}`}</p>
                <AllurionSwitch
                  name="notifications_enabled"
                  label={intl.formatMessage(messages.messageNotificationsToggleLabel)}
                  checked={notificationsEnabled}
                  onChange={changeMessageNotifications}
                />

                {notificationsEnabled && (
                  <SubItem data-cy="message-notifications-frequency">
                    <ValueLabel>
                      {intl.formatMessage(messages.messageNotificationsNotifyMe)}:
                    </ValueLabel>
                    <AllurionRadioButtons
                      onSelect={changeNotificationsFrequency}
                      defaultValue={notificationsFrequency}
                      options={messageNotificationsOptions}
                    />
                  </SubItem>
                )}
              </Item>
            )}
            <Controls>
              <div>
                <ResetPasswordLink to="/reset-password">
                  {intl.formatMessage(messages.resetPassword)}
                </ResetPasswordLink>
              </div>
              <div>
                <ButtonWrapper>
                  <TrackedButton
                    type="submit"
                    label={intl.formatMessage(messages.saveProfile)}
                    onClick={submitForm}
                    disabled={!hasChanges || firstName === '' || lastName === ''}
                    size="sm"
                    trackLabel="submit-profile-form"
                  />
                </ButtonWrapper>
              </div>
            </Controls>
          </FormikProvider>
        </InnerContainer>
        <Loader isLoading={isLoading || isLoadingClinics} />
      </Container>
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  max-width: 914px;
  margin-top: 25px;
  ${({ theme }) => theme.media.max.mobile`
    flex-direction: column;
  `}
  ${({ theme }) => theme.media.min.mobile`
    margin-top: 50px;
    > div {
      width: 50%;
      &:first-of-type {
        margin-right: 26px;
      }
    }
  `}
`;

const Item = styled.div`
  margin-top: 32px;
  color: ${({ theme }) => theme.colors.Primary};
  p {
    margin: 5px 0;
    line-height: 20px;
    font-size: 13px;
  }
`;

const SubItem = styled(Item)`
  margin: 24px 0 0 20px;
`;

const ValueLabel = styled.label`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-weight: 500;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
`;

const Value = styled.div`
  font-family: 'GT-America-Standard-Regular', sans-serif;
  font-size: 13px;
  line-height: 20px;
  margin-top: 5px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.Primary};
  margin-top: 40px;
  padding-bottom: 2px;
`;

const ButtonWrapper = styled.div`
  max-width: 400px;
  margin-top: 80px;
`;

const ResetPasswordLink = styled(StyledLink)`
  color: ${({ theme }) => theme.colors.LinkBlue} !important;
`;
