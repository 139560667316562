import { Table } from '@allurion/ui';
import { isNumber } from '@allurion/utils';
import * as React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { PatientsAccess } from 'src/domain/patient/Patients';
import { Checkbox } from 'src/ui/Checkbox';

import { Pagination } from '../../components/Pagination';

type Props = {
  patients: PatientsAccess[];
  totalPages: number;
  onCheckPatient: (pat?: PatientsAccess, isAll?: boolean) => void;
  onSort: (col: string) => void;
  selectedPatients: PatientsAccess[];
  setPage: React.Dispatch<
    React.SetStateAction<{
      index: number;
      size: number;
    }>
  >;
  page: { index: number; size: number };
  isLoading?: boolean;
  sortBy: string;
  defaultPageSize: number;
};

export function ManagePatientsTable({
  patients = [],
  totalPages = 1,
  onCheckPatient = () => {},
  onSort = () => {},
  selectedPatients = [],
  page,
  setPage = () => {},
  isLoading,
  sortBy,
  defaultPageSize,
}: Props) {
  const intl = useIntl();

  const updatePage = (nextPage: React.SetStateAction<number>): void => {
    setPage((pg) => ({
      ...pg,
      index: isNumber(nextPage) ? nextPage : nextPage(pg.index),
    }));
  };
  const updatePageSize = (nextPageSize: React.SetStateAction<number>): void => {
    setPage((pg) => ({
      ...pg,
      size: isNumber(nextPageSize) ? nextPageSize : nextPageSize(pg.size),
    }));
  };

  const isSelectedAll = patients?.length > 0 && selectedPatients?.length === patients?.length;
  const selectAll = () => {
    onCheckPatient(undefined, !isSelectedAll);
  };
  const isIndet = selectedPatients?.length > 0 && selectedPatients?.length < patients?.length;

  return (
    <Container>
      <Table
        columns={[
          {
            field: 'patient_id',
            title: <Checkbox onClick={selectAll} checked={isSelectedAll} indeterminate={isIndet} />,
            formatter: (value, row) => {
              const isSelected = !!selectedPatients?.filter((s) => s.patient_id === value)?.length;
              const handleSelection = () => {
                onCheckPatient(row);
              };

              return <Checkbox onClick={handleSelection} checked={isSelected} />;
            },
            sortable: false,
          },
          {
            title: intl.formatMessage({
              id: 'manage-patients.table.last-name',
              defaultMessage: 'Last Name',
            }),
            field: 'lastname',
          },
          {
            title: intl.formatMessage({
              id: 'manage-patients.table.first-name',
              defaultMessage: 'First Name',
            }),
            field: 'firstname',
          },
          {
            title: intl.formatMessage({
              id: 'manage-patients.table.email',
              defaultMessage: 'Email',
            }),
            field: 'email_address',
            sortable: false,
          },
          {
            title: intl.formatMessage({
              id: 'manage-patients.table.age',
              defaultMessage: 'Age',
            }),
            field: 'age',
            sortable: false,
          },
          {
            title: intl.formatMessage({
              id: 'manage-patients.table.gender',
              defaultMessage: 'Gender',
            }),
            field: 'gender',
            formatter: (value) =>
              value ? ((value as string).toLocaleLowerCase() === 'male' ? 'M' : 'F') : '',
            sortable: false,
          },
          {
            title: intl.formatMessage({
              id: 'manage-patients.table.providers',
              defaultMessage: 'Providers',
            }),
            field: 'providers_count',
            sortable: false,
          },
        ]}
        data={patients}
        sortBy={sortBy}
        onSortChange={onSort}
      />
      {!patients?.length && !isLoading && (
        <EmptyData>
          {intl.formatMessage({
            id: 'manage-patients.table.no-records',
            defaultMessage: 'No records found',
          })}
        </EmptyData>
      )}
      <Pagination
        page={page.index}
        setPage={updatePage}
        pageSize={page.size}
        setPageSize={updatePageSize}
        lastPage={totalPages}
        defaultPageSize={defaultPageSize}
        pageSizeLabel={intl.formatMessage({
          id: 'manage-patients.table.page-size',
          defaultMessage: 'Patients per page',
        })}
      />
    </Container>
  );
}

const Container = styled.div`
  .custom-table {
    margin-bottom: 0;
    th {
      font-family: 'GT-America-Standard-Medium', sans-serif;
      text-align: center;
    }

    td {
      font-family: 'GT-America-Standard-Regular', sans-serif;
      font-size: 16px;
    }

    th,
    td {
      border-left: none;
      border-right: none;
    }

    td:nth-child(1),
    td:nth-child(6) {
      text-align: center;
    }

    td:nth-child(5),
    td:nth-child(7) {
      text-align: right;
    }
  }
`;

const EmptyData = styled.p`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.White};
  padding: 20px;
`;
