import { AddPatientPayload } from 'src/domain/patient/Patients';

import { buildUrl, InsightsApi } from './Api';

export const SEND_ARTICLE_NOTIFICATION = (patientId: string) =>
  `/patients/${patientId}/articles/send-notification`;
export const PATIENT_INVITE = (patientId: string) => `/patients/${patientId}/invite`;
export const CLINIC_ADD_PATIENT = (clinicId: string) => `/clinics/${clinicId}/patients`;

export async function invitePatient({
  startDate,
  firstName,
  lastName,
  ...data
}: AddPatientPayload) {
  const body = {
    ...data,
    firstname: firstName,
    lastname: lastName,
    programStartDate: startDate,
  };

  return InsightsApi.post(buildUrl(CLINIC_ADD_PATIENT(data?.clinic)), body);
}

export function resendPatientInvitation(patientId: string) {
  return InsightsApi.post<{
    CodeDeliveryDetails?: {
      AttributeName?: string;
    };
  }>(buildUrl(PATIENT_INVITE(patientId)));
}

export function sendArticleNotification(
  patientId: string,
  articleId: string,
  collectionId: string,
  locale: string
) {
  return InsightsApi.post(buildUrl(SEND_ARTICLE_NOTIFICATION(patientId)), {
    article_id: articleId,
    collection_id: collectionId,
    locale,
  });
}
