import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import styled from 'styled-components';

interface Item {
  label: string | JSX.Element;
  value: string | number;
}

type Props = {
  data: Item[];
  renderCard: (item: Item, index: number) => JSX.Element;
};

export function ScrollingMenu({ data, renderCard }: Props) {
  return (
    <Container>
      <ScrollMenu>
        {data.map((item, index: number) => (
          <div key={index} className="menu-item">
            {renderCard(item, index)}
          </div>
        ))}
      </ScrollMenu>
    </Container>
  );
}

const Container = styled.div`
  div {
    /* border: none !important; */
    outline: none !important;
  }
  .menu-item {
    user-select: none;
    cursor: pointer;
    border: none;
    outline: none;
  }

  .scroll-menu-arrow {
    /* padding: 20px; */
    cursor: pointer;
  }
`;
