import { SelectedDevice } from '../video-call-types';

import { SettingsControls as SettingsControl } from './SettingsControls';
import { SettingsWrapper } from './styles';

type Props = {
  onSave?: (device: SelectedDevice) => void;
  isCallOver?: boolean;
  isOpen: boolean;
};

export function VideoCallSettings({ onSave = () => {}, isCallOver, isOpen }: Props) {
  return (
    <SettingsWrapper>
      {isOpen && <SettingsControl onChangeDevice={onSave} isCallOver={isCallOver} />}
    </SettingsWrapper>
  );
}
