import { IntlShape } from 'react-intl';

import globalMessages from 'src/messages/global.messages';

export function validate(intl: IntlShape) {
  return (values: { firstName?: string; lastName?: string; date?: string; terms?: boolean }) => {
    const errors: {
      firstName?: string;
      lastName?: string;
      date?: string;
      terms?: string;
    } = {};

    if (!values.terms) {
      errors.terms = intl.formatMessage(globalMessages.required);
    }

    if (!values.firstName) {
      errors.firstName = intl.formatMessage(globalMessages.required);
    }

    if (!values.lastName) {
      errors.lastName = intl.formatMessage(globalMessages.required);
    }

    if (!values.date) {
      errors.date = intl.formatMessage(globalMessages.required);
    }

    return errors;
  };
}
