import { PageHeader } from '@allurion/ui';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { Container, InnerContainer } from 'src/pages/shared-page-elements';

import translations from '../ManageUsers.translations';

import { ExpirationWarning } from './ExpirationWarning';

type Props = {
  isDeletedUser: boolean;
  isUpdatedUser: boolean;
  state: {
    formEmail: string;
    formFirstName: string;
    formLastName: string;
    clinicLabel: string;
    roleLabel: string;
  };
  goBack: () => void;
};

export function ActionComplete({ isDeletedUser, isUpdatedUser, state, goBack }: Props) {
  const { formEmail, formFirstName, formLastName, clinicLabel, roleLabel } = state;
  const intl = useIntl();

  let title = translations.actionCompleteTitle;

  if (isDeletedUser) {
    title = translations.actionCompleteDeleteTitle;
  } else if (isUpdatedUser) {
    title = translations.actionCompleteUpdateTitle;
  }
  const isInviteConfirm = !isDeletedUser && !isUpdatedUser;

  const listItems = [
    {
      label: intl.formatMessage(translations.actionCompleteName),
      value: `${formFirstName} ${formLastName}`,
    },
    {
      label: intl.formatMessage(translations.actionCompleteClinicName),
      value: clinicLabel,
      show: !!clinicLabel,
    },
    {
      label: intl.formatMessage(translations.actionCompleteEmail),
      value: formEmail,
    },
    {
      label: intl.formatMessage(translations.actionCompleteRole),
      value: roleLabel,
      show: !!roleLabel,
    },
  ];

  const btnLabel = intl.formatMessage(translations.actionCompleteCloseButton);

  return (
    <>
      <PageHeader title={intl.formatMessage(translations.actionCompleteSuccess)} />
      <Container>
        <InnerContainer>
          <Title>{intl.formatMessage(title)}</Title>
          {isInviteConfirm && <ExpirationWarning hoursUntilExpires={48} />}
          <Items>
            {listItems.map(
              ({ label, value, show = true }) =>
                show && (
                  <li key={`action-complete_${label}`}>
                    <Label>{label}</Label>
                    <Value>{value}</Value>
                  </li>
                )
            )}
          </Items>
          <TrackedButton
            label={btnLabel}
            onClick={goBack}
            trackLabel="acknowledge-successful-action"
          />
        </InnerContainer>
      </Container>
    </>
  );
}

const Title = styled.h2`
  font-size: 20px;
  max-width: 700px;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.Primary};
  margin-bottom: 16px;
`;

const Items = styled.ul`
  margin-top: 50px;
  li {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 25px;
    &:not(:first-of-type) {
      padding-top: 25px;
    }
    &:last-child {
      margin-bottom: 25px;
  }
  color: ${({ theme }) => theme.colors.Primary};
`;

const Label = styled.label`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.Primary};
`;

const Value = styled.div`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-size: 20px;
  margin-top: 20px;
`;
