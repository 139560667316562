import styled from 'styled-components';

type Props = {
  name: string;
  label: string;
  checked: boolean;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
};

function AllurionSwitch({ label, checked, onChange, name }: Props) {
  return (
    <Container>
      <SwitchContainer>
        <input
          type="checkbox"
          className="switch-checkbox"
          onChange={onChange}
          checked={checked}
          data-cy="switchInput"
          value="true"
          name={name}
        />
        <div className="base">
          <div className="handler" />
        </div>
      </SwitchContainer>
      <Label>{label}</Label>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchContainer = styled.label`
  clear: both;
  position: relative;
  cursor: pointer;
  .switch-checkbox {
    height: 0;
    width: 0;
    opacity: 0;
  }

  .base {
    width: 36px;
    height: 24px;
    background: ${({ theme }) => theme.colors.DarkSilver};
    position: relative;
    border-radius: 50px;
  }

  .handler {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #fff;
    position: absolute;
    left: 3px;
    top: 3px;
    transition: all 0.5s ease-out 0s;
  }

  .switch-checkbox:checked ~ .base {
    background: ${({ theme }) => theme.colors.SeaGreen}!important;
    .handler {
      transition: all 0.5s ease-out 0s;
      left: 15px;
    }
  }
`;
const Label = styled.div`
  font-family: 'GT-America-Standard-Medium', sans-serif;
  font-weight: 500;
  font-size: 14px;
  margin: 16px 0 0 16px;
`;

export default AllurionSwitch;
