import { Card } from '@allurion/ui';

import { TrackedEmptyState } from 'src/analytics/TrackedUI';
import { ActivityListItem } from 'src/hooks/usePatientActivityData';

import { GraphCardProps, ActivityCardBody } from './ActivityCardBody';
import { ActivityCardHeader } from './ActivityCardHeader';

type Props = {
  Icon: React.FC;
  title: string;
  valueLabel: string;
  middleValueLabel?: string;
  averageValue: string;
  data: ActivityListItem[];
  graph: GraphCardProps;
  fetchMoreData: () => void;
  isLoading: boolean;
  noDataTitle: string;
  noDataSubtitle: string;
  noDataTrackLabel: string;
};

export function ActivityCard({
  Icon,
  title,
  averageValue,
  valueLabel,
  middleValueLabel,
  data,
  graph,
  fetchMoreData,
  isLoading,
  noDataTitle,
  noDataSubtitle,
  noDataTrackLabel,
}: Props) {
  const hasMiddleValue = !!middleValueLabel;
  const label = middleValueLabel;

  const hasActivityData = data.length !== 0 && !isLoading;

  return (
    <Card title={title}>
      <ActivityCardHeader Icon={Icon} averageValue={hasActivityData ? averageValue : ''} />

      {hasActivityData ? (
        <>
          <ActivityCardBody
            graphId={graph.graphId}
            graphBarColor={graph.graphBarColor}
            stepSize={graph.stepSize}
            graphData={graph.graphData}
            ytickFormatter={graph.ytickFormatter}
            tooltipLabelFormatter={graph.tooltipLabelFormatter}
            label={label || ''}
            data={data}
            hasMiddleValue={hasMiddleValue}
            valueLabel={valueLabel}
            fetchMoreData={fetchMoreData}
            isLoading={isLoading}
          />
        </>
      ) : (
        <TrackedEmptyState
          title={noDataTitle}
          subtitle={noDataSubtitle}
          icon={<Icon />}
          trackedLabel={noDataTrackLabel}
        />
      )}
    </Card>
  );
}
