import { AtRiskDisplay, isPatientAtRisk } from '@allurion/domain';
import { Badge, CheckIcon, FlagIcon } from '@allurion/ui';
import { isNull } from '@allurion/utils';
import { useIntl } from 'react-intl';

type Props = {
  at_risk_display: AtRiskDisplay;
  at_risk_score: number;
};

export function PatientSuccessPredictorBadge({ at_risk_display, at_risk_score }: Props) {
  const intl = useIntl();

  const isAtRisk = isPatientAtRisk(at_risk_score, at_risk_display);

  if (isNull(isAtRisk)) {
    return null;
  }

  return (
    <Badge
      label={
        isAtRisk
          ? intl.formatMessage({
              id: 'success-predictor.not-on-track-badge',
              defaultMessage: 'Not on track',
            })
          : intl.formatMessage({
              id: 'success-predictor.On-track-badge',
              defaultMessage: 'On track',
            })
      }
      variant={isAtRisk ? 'danger-light' : 'success-light'}
      leftIcon={isAtRisk ? <FlagIcon /> : <CheckIcon />}
      size="sm"
    />
  );
}
