import { Formik } from 'formik';
import { useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { AllurionCheckboxes } from 'src/components/ui/AllurionCheckboxes';
import { AllurionDatePicker } from 'src/components/ui/AllurionDatePicker';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';
import { joinClinicNames } from 'src/helpers/string';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { useUserClinics } from 'src/hooks/useUserClinics';

import messages from './terms-and-conditions-messages';
import { validate } from './terms-and-conditions-validate';

type Props = {
  initialValues: {
    firstName?: string;
    lastName?: string;
    date?: string;
    terms?: boolean;
  };
  onSubmit: (values: {
    firstName?: string;
    lastName?: string;
    date?: string;
    terms?: boolean;
  }) => void;
};

export function TermsAndConditionsForm({ initialValues, onSubmit }: Props) {
  const intl = useIntl();
  const [localIsOpen, setLocalIsOpen] = useState(false);
  const { user } = useCurrentUser();
  const { clinics: locations } = useUserClinics();

  const personalizedAgreement = useMemo(() => {
    if (!user) {
      return intl.formatMessage(messages.terms);
    }

    const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;

    const locationsNames = locations?.map((c) => c?.post_title) || 'Allurion';
    const clinicName = joinClinicNames(
      locationsNames,
      intl.formatMessage({ id: 'global.and', defaultMessage: 'and' })
    );
    // const clinicName: string = clinic?.post_title ?? 'Allurion';

    // NOTE: We can update the translations to use format => {firstName lastName}, {clinicName}
    // instead of <Clinic Name> and <First Name Last Name>
    // to drop the in-house replace implementation, removes: nameReplacement, clinicReplacement and finalString
    const template = intl.formatMessage(messages.terms);
    const nameReplacement = template.replace('<First Name Last Name>', fullName);
    const clinicReplacement = nameReplacement.replace('<Clinic Name>', clinicName);
    const finalString = clinicReplacement.replace('<Clinic Name>', clinicName);

    return finalString;
  }, [intl, user, locations]);

  return (
    <Formik initialValues={initialValues} validate={validate(intl)} onSubmit={onSubmit}>
      {({
        handleSubmit,
        isSubmitting,
        dirty,
        errors,
        setFieldTouched,
        setFieldValue,
        submitCount,
        touched,
      }) => {
        const hasErrors = Object.keys(errors).length > 0;
        const hasTouched = Object.keys(touched).length > 0; // Checkbox and Date have to be touched
        const disabled =
          (!hasTouched && !submitCount) || isSubmitting || !localIsOpen || (!dirty && hasErrors);

        const checkboxVal = 'accepted';

        const handleCheckbox = (values: string) => {
          const isChecked = values.includes(checkboxVal);

          setLocalIsOpen(isChecked); // TODO: Remove this react state, use formik's
          setFieldValue('terms', isChecked);
        };

        const handleDatePicker = (newDate: Date) => {
          setFieldTouched('date', true);
          setFieldValue('date', newDate);
        };

        const checkboxOtps = [{ label: personalizedAgreement, value: checkboxVal }];

        return (
          <Form onSubmit={handleSubmit}>
            <TopLabel>
              <AllurionCheckboxes
                name="accepted"
                options={checkboxOtps}
                onChecked={handleCheckbox}
              />
            </TopLabel>
            <Text>{intl.formatMessage(messages.instructions)}</Text>
            <Row>
              <FieldWrapper>
                <AllurionTextField
                  name="firstName"
                  placeholder={intl.formatMessage(messages.firstName)}
                  label={intl.formatMessage(messages.firstName)}
                  disabled
                />
              </FieldWrapper>
              <FieldWrapper>
                <AllurionTextField
                  name="lastName"
                  placeholder={intl.formatMessage(messages.lastName)}
                  label={intl.formatMessage(messages.lastName)}
                  disabled
                />
              </FieldWrapper>
            </Row>
            <FieldWrapper>
              <Label>{intl.formatMessage(messages.todaysDate)}</Label>
              <AllurionDatePicker name="date" onSelect={handleDatePicker} />
            </FieldWrapper>
            <Controls>
              <TrackedButton
                type="submit"
                disabled={disabled}
                label={intl.formatMessage(messages.submit)}
                trackLabel="submit-terms-and-conditions"
              />
            </Controls>
          </Form>
        );
      }}
    </Formik>
  );
}

const Form = styled.form`
  max-width: 800px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.media.max.tablet`
    flex-direction: column;
  `}
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 65px;
`;

const FieldWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.min.tablet`
    width: 50%;
    margin-bottom: 25px;
    &:first-of-type {
      margin-right: 25px;
    }
  `}
`;

const TopLabel = styled.div`
  font-size: 18px;
  cursor: pointer;
  margin-top: 60px;
  margin-bottom: 60px;
`;

const Label = styled.div`
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 15px;
`;

const Text = styled.div`
  font-size: 18px;
  margin-bottom: 45px;
`;
