import { useIntl } from 'react-intl';

import messages from './activity-card-messages';

import styles from './ActivityCard.module.scss';

type Props = {
  averageValue: string;
  Icon: React.FC;
};

export function ActivityCardHeader({ averageValue, Icon }: Props) {
  const intl = useIntl();

  return (
    <div className={styles.header}>
      {averageValue && (
        <div className={styles.header__card}>
          <div className={styles.header__card__icon}>
            <Icon />
          </div>
          <div className={styles.header__card__content}>
            <div className={styles.header__card__content__title}>
              {intl.formatMessage({ id: messages.average7Days.id })}:{' '}
            </div>
            <div className={styles.header__card__content__value}>{averageValue}</div>
          </div>
        </div>
      )}
    </div>
  );
}
