import { IntlShape } from 'react-intl';

import { ParsedClinicData } from 'src/domain/Clinic';
import globalMessages from 'src/messages/global.messages';

export const validate =
  (isEditForm: boolean, intl: IntlShape) =>
  (values: {
    clinics?: ParsedClinicData[];
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: { value: string; label: string };
  }) => {
    const errors: {
      clinics?: string;
      firstName?: string;
      lastName?: string;
      email?: string;
      role?: string;
    } = {};

    if (isEditForm && values.role && values.role.value === 'delete') {
      return errors;
    }

    if (!values.clinics || values.clinics.length === 0) {
      errors.clinics = intl.formatMessage(globalMessages.selectClinic);
    }

    if (!values.firstName) {
      errors.firstName = intl.formatMessage(globalMessages.required);
    }

    if (!values.lastName) {
      errors.lastName = intl.formatMessage(globalMessages.required);
    }

    if (!values.email) {
      errors.email = intl.formatMessage(globalMessages.notValidEmail);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = intl.formatMessage(globalMessages.notValidEmail);
    }

    if (!values.role) {
      errors.role = intl.formatMessage(globalMessages.required);
    }

    return errors;
  };
