import { useState } from 'react';
import { useIntl } from 'react-intl';
import { components } from 'react-select';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { AllurionSelect } from 'src/components/ui/AllurionSelect';
import { PatientsAccess } from 'src/domain/patient/Patients';
import { Provider } from 'src/domain/Provider';
import { WarningIcon } from 'src/icons/WarningIcon';

import { AssignedProvidersWarning } from './AssignedProvidersWarning';

type Props = {
  defaultValue?: Provider[];
  providers: Provider[];
  onCancel: () => void;
  onNext: (selected: Provider[]) => void;
  selectedPatients: PatientsAccess[];
};

function Input({ ...props }) {
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: 'provider-access.form.input-placeholder',
    defaultMessage: 'Search or select...',
  });

  // @ts-ignore
  return <components.Input placeholder={placeholder} {...props} />;
}

export function ProvidersForm({
  defaultValue,
  selectedPatients,
  providers,
  onCancel,
  onNext,
}: Props) {
  const [selected, setSelected] = useState<Provider[]>();
  const intl = useIntl();

  const onSelectAll = () => {
    setSelected(providers);
  };

  const handleOnChange = (p: Provider[]) => {
    setSelected(p);
  };

  const handleOnNext = () => {
    onNext(selected!);
  };

  const getOptionValue = (opt: Provider) => opt.ProviderID;
  const getOptionLabel = (opt: Provider) => `${opt.FirstName} ${opt.LastName}`;

  const showMinimumWarning = selected && selected?.length < 1;

  return (
    <>
      <SelectedPatients>
        <SelectLabel>
          {intl.formatMessage({
            id: 'provider-access.form.patients-label',
            defaultMessage: 'Selected patients:',
          })}
        </SelectLabel>
        <SelectedPatientsList>
          <em>
            {selectedPatients
              .map((patient) => `${patient.firstname} ${patient.lastname ?? ''}`)
              .join(', ')}
          </em>
        </SelectedPatientsList>
      </SelectedPatients>

      <AssignedProviders>
        <SelectRow>
          <SelectLabel>
            {intl.formatMessage({
              id: 'provider-access.form.input-label-alt',
              defaultMessage: 'Providers with access:',
            })}
          </SelectLabel>
          <SelectAll onClick={onSelectAll}>
            {intl.formatMessage({
              id: 'provider-access.form.select-all',
              defaultMessage: 'Select all',
            })}
          </SelectAll>
        </SelectRow>
        <AllurionSelect
          isMulti
          options={providers}
          defaultValue={defaultValue}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          value={selected}
          onChange={handleOnChange}
          components={{ Input }}
          // NOTE: We define the placeholder in the above component `Input`
          // we need an empty space so we don't render the default placeholder from react-select!
          placeholder=" "
        />

        {showMinimumWarning && (
          <MinimumSelectionWarning>
            <WarningIcon />
            {intl.formatMessage({
              id: 'provider-access.minimum-selection-warning',
              defaultMessage: 'You must select at least one provider.',
            })}
          </MinimumSelectionWarning>
        )}
        <AssignedProvidersWarning />
      </AssignedProviders>
      <ButtonsContainer>
        <TrackedButton
          onClick={onCancel}
          label={intl.formatMessage({
            id: 'provider-access.form.cancel',
            defaultMessage: 'Cancel',
          })}
          variant="outlined"
          size="sm"
          trackLabel="cancel-edit-patient-providers"
        />
        <TrackedButton
          onClick={handleOnNext}
          label={intl.formatMessage({
            id: 'provider-access.form.next',
            defaultMessage: 'Next',
          })}
          size="sm"
          disabled={!selected?.length}
          trackLabel="next-edit-patient-providers"
        />
      </ButtonsContainer>
    </>
  );
}

const AssignedProviders = styled.div`
  background: ${({ theme }) => theme.colors.White};
  margin: 20px 0 0 0;
  padding: 24px;

  background: ${({ theme }) => theme.colors.White};
  */ .react-select__control {
    background-color: ${({ theme }) => theme.colors.White};
    border: 1px solid ${({ theme }) => theme.colors.DarkSilver};
    max-height: 110px;
    overflow-y: scroll;
  }
  .react-select__multi-value {
    background-color: ${({ theme }) => theme.colors.Silver};
  }
  .react-select__multi-value__label {
    color: ${({ theme }) => theme.colors.Primary};
    padding: 8px;
  }
  .react-select__multi-value__remove {
    color: ${({ theme }) => theme.colors.SoftGrey};
    scale: 1.25;
  }
  .react-select__indicators {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .react-select__indicator {
    color: ${({ theme }) => theme.colors.Primary};
    padding: 0;
    margin: 0 5px 0 0;
  }

  .react-select__input-container {
    padding-right: 50px;
    width: 100%;
    order: -1;
    input {
      position: relative;
      padding: 40px;
      top: -5px;
      width: auto !important;
    }
    input::placeholder {
      color: ${({ theme }) => theme.colors.SoftGrey};
      font-family: 'GT-America-Standard-Light', sans-serif;
    }
  }
  .react-select__menu {
    top: 36px;
  }
`;

const MinimumSelectionWarning = styled.div`
  margin: 16px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.Alert};
  .MuiSvgIcon-root {
    margin: 0 16px 0 0;
  }
  * {
    color: ${({ theme }) => theme.colors.Alert};
  }
`;
const SelectedPatients = styled.div`
  background: ${({ theme }) => theme.colors.White};
  padding: 24px;
`;

const SelectedPatientsList = styled.div`
  font-size: 13px;
  max-height: 53px;
  overflow-y: scroll;
`;

const ButtonsContainer = styled.div`
  button {
    margin-right: 20px;
  }
  display: flex;
  justify-content: right;
  margin-top: 30px;
`;

const SelectLabel = styled.p`
  font-family: 'GT-America-Standard-medium', sans-serif;
  color: ${({ theme }) => theme.colors.Primary};
  font-weight: 500;
  font-size: 16px;
`;

const SelectAll = styled.button`
  color: ${({ theme }) => theme.colors.LinkBlue};
  text-decoration: underline;
  background-color: inherit;
  cursor: pointer;
  font-family: 'GT-America-Standard-Light', sans-serif;
  padding: 0;
  font-size: 16px;
`;

const SelectRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;
