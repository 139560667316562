import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createLocalAudioTrack, LocalAudioTrack } from 'twilio-video';

import { StyledSelect } from 'src/components/ui/ReactSelect';
import { MicOutlinedIcon } from 'src/icons/MicIcon';
import { Label } from 'src/styles/components';
import { AUDIO_INPUT_ID } from 'src/utils/constants';

import { DeviceOption } from '../video-call-types';
import messages from '../video-call.messages';

import { AudioLevelIndicator } from './AudioLevelIndicator';
import { MicContainer, NoDeviceLabel } from './styles';

type Props = {
  microphones: MediaDeviceInfo[];
  onSelection: (device: DeviceOption) => void;
  isCallOver?: boolean;
  onPermissionError: (error: unknown) => void;
};

export function MicrophoneSelector({
  microphones,
  onSelection = () => {},
  isCallOver,
  onPermissionError = () => {},
}: Props) {
  const [microphone, setMicrophone] = useState<DeviceOption | null>(null);
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | null>(null);
  const intl = useIntl();

  const onChange = (device: DeviceOption) => {
    const selectedDevice = {
      ...device,
      kind: 'audioinput',
    };

    onSelection(selectedDevice);
    setMicrophone(device);
  };

  const message = intl.formatMessage(messages.microphone);
  const noDeviceMessage = intl.formatMessage(messages.noDevices);
  const selectPlaceholder = intl.formatMessage(messages.microphoneSelectorPlaceholder);
  const isDisabled = microphones?.length < 1;
  const options = microphones?.map(({ deviceId, label }) => ({
    id: deviceId,
    value: deviceId,
    label,
  }));

  useEffect(() => {
    const initTrack = async () => {
      const track = await createLocalAudioTrack();

      setAudioTrack(track);
    };
    const clearTrack = async () => {
      audioTrack?.stop();
      audioTrack?.detach();
    };

    if (isCallOver && !audioTrack) {
      try {
        initTrack();
      } catch (error: unknown) {
        onPermissionError(error);
      }
    } else if (audioTrack && !isCallOver) {
      clearTrack();
    }

    return () => {
      clearTrack();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioTrack, isCallOver]);

  const value = microphone || noDeviceMessage;
  const noMicrophoneMessage = intl.formatMessage(messages.askMicrophone);
  const isMicrophoneAvailable = !!microphones.length;

  return (
    <MicContainer>
      <Label htmlFor={AUDIO_INPUT_ID}>
        <MicOutlinedIcon size="16px" />
        {message}
      </Label>
      {!isMicrophoneAvailable ? (
        <NoDeviceLabel>{noMicrophoneMessage}</NoDeviceLabel>
      ) : (
        <>
          <StyledSelect
            id={AUDIO_INPUT_ID}
            data-testid={AUDIO_INPUT_ID}
            isDisabled={isDisabled}
            onChange={onChange as any}
            options={options}
            isSearchable={false}
            value={value}
            placeholder={selectPlaceholder}
          />
          <AudioLevelIndicator audioTrack={audioTrack} />
        </>
      )}
    </MicContainer>
  );
}
