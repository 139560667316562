import { AtRiskDisplay } from './patient';
import { isNull } from '@allurion/utils';

const AT_RISK_THREADSHOLD = 0.5;

const atRisk = {
  'At Risk': true,
  'Not At Risk': false,
};

export function isPatientAtRisk(atRiskScore: number | null, atRiskDisplay: AtRiskDisplay) {
  return !isNull(atRiskDisplay)
    ? atRisk[atRiskDisplay]
    : !isNull(atRiskScore)
      ? atRiskScore > AT_RISK_THREADSHOLD
      : null;
}
