import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { PasswordPopup } from 'src/components/PasswordPopup/PasswordPopup';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';

import { validate } from './create-password-validate';
import messages from './create-password.messages';

import styles from './CreatePasswordForm.module.scss';

type Props = {
  onSubmit: ({ password }: { password: string }) => void;
};

export function CreatePasswordForm({ onSubmit }: Props) {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <AllurionTextField
            darkbg
            name="password"
            type="password"
            label={
              <div className={styles.passwordLabel}>
                {intl.formatMessage(messages.createPassword)}
                <PasswordPopup />
              </div>
            }
            placeholder={intl.formatMessage(messages.createPassword)}
          />

          <AllurionTextField
            darkbg
            name="confirmPassword"
            type="password"
            label={intl.formatMessage(messages.confirmPassword)}
            placeholder={intl.formatMessage(messages.confirmPassword)}
          />

          <TrackedButton
            type="submit"
            label={intl.formatMessage(messages.submit)}
            disabled={isSubmitting}
            variant="secondary"
            trackLabel="submit-create-password"
          />
        </form>
      )}
    </Formik>
  );
}
