import { useField } from 'formik';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { AllurionErrorIndicator } from 'src/components/ui/AllurionErrorIndicator';
import { AllurionSelect } from 'src/components/ui/AllurionSelect';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';

import translations from './PatientTreamentsCard.translations';

export function TreatmentSelector({
  clinicId,
  selectTreatmentTypeID,
  name,
  treatmentsOffered = [],
  onChange,
  ...props
}: {
  name: string;
  clinicId: string;
  selectTreatmentTypeID?: string;
  treatmentsOffered?: number[] | string[];
  onChange?: (value: string | number) => void;
}) {
  const intl = useIntl();
  const [, meta, helpers] = useField({ name, ...props });
  const { error, touched } = meta || {};

  const { getTreatmentTypeLabel } = useTreatmentLabels(clinicId);

  const treatmentsOptions = treatmentsOffered.map((treatmentId) => ({
    label: getTreatmentTypeLabel(treatmentId),
    value: treatmentId,
  }));

  const selectedOption = treatmentsOptions.find(({ value }) => value === selectTreatmentTypeID);

  const handleChange = (
    selectedValue:
      | { value: string | number; label: string }
      | { value: string | number; label: string }[]
  ) => {
    const value = Array.isArray(selectedValue) ? selectedValue[0].value : selectedValue.value;

    helpers.setValue(value);
    helpers.setError(undefined);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Container>
      <AllurionSelect
        onChange={handleChange}
        options={treatmentsOptions}
        name={name}
        placeholder={intl.formatMessage(translations.treatmentTypePlaceholder)}
        defaultValue={selectedOption}
        haserror={error}
      />
      <AllurionErrorIndicator label={error} show={touched} data-cy="errTreatmentSelector" />
    </Container>
  );
}

const Container = styled.div`
  .react-select__single-value {
    font-size: 16px !important;
    padding-top: 6px;
  }
  .react-select__menu {
    position: static;
  }
`;
