type Props = {
  width?: string | number;
  height?: string | number;
  stroke?: string;
  style?: object;
};

export function CarrotDown({ width = '10', height = '7', stroke = '#111836', style = {} }: Props) {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50938 1.42483L4.75469 5.33862L1 1.42483"
        stroke={stroke}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CarrotUp({ style, ...props }: Props) {
  return <CarrotDown {...props} style={{ ...(style || {}), transform: 'rotate(180deg)' }} />;
}
