import {
  Card,
  FileIcon,
  PencilIcon,
  PlusIcon,
  Tooltip,
  TrashCanIcon,
  useConfirmationDialog,
  useDialog,
} from '@allurion/ui';
import { isError, toLocalizedDate } from '@allurion/utils';
import uniqueId from 'lodash.uniqueid';
import { ComponentProps, useState } from 'react';
import { useIntl } from 'react-intl';

import { useTrackEvent } from 'src/analytics/analytics';
import { TrackedEmptyState, TrackedIconButton } from 'src/analytics/TrackedUI';
import { toastError } from 'src/components/ui/toasts';
import { PatientTreatment } from 'src/domain/patient/Patients';
import { useClinicTreatements } from 'src/hooks/useClinicCustomTreatments';
import { useLocale } from 'src/hooks/useLocale';
import { usePatientTreatments } from 'src/hooks/usePatientTreatments';
import { useTreatmentLabels } from 'src/hooks/useTreatmentLabels';
import globalMessages from 'src/messages/global.messages';
import { Logger } from 'src/services/Logger';

import { InlineLoader } from '../../../../components/ui/InlineLoader';

import translations from './PatientTreamentsCard.translations';
import { TreatmentForm } from './TreatmentForm';

import styles from './PatientTreamentsCard.module.scss';

type Props = {
  patientId: string;

  clinicId: string;
  readOnly?: boolean;
  cardTitle: string;
};

export function PatientTreamentsCard({ patientId, clinicId, readOnly = false, cardTitle }: Props) {
  const intl = useIntl();
  const { locale } = useLocale();
  const { openDialog, Dialog } = useDialog<ComponentProps<typeof TreatmentForm>>({
    content: ({ close, ...contentProps }) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <TreatmentForm
            {...contentProps}
            onSave={async (treatment) => {
              await contentProps.onSave(treatment);
              close?.();
            }}
            onCancel={() => {
              close?.();
            }}
          />
        </div>
      );
    },
  });

  const { treatments: clinicTreatments } = useClinicTreatements(clinicId);
  const treatmentsOffered = clinicTreatments.map((treatment) => treatment.custom_treatment_id);

  const [editTreatments, setEditTreatments] = useState<number[]>([]);
  const { treatments, createTreatment, updateTreatment, deleteTreatment, isLoading } =
    usePatientTreatments(patientId);
  const { getTreatmentTypeLabel } = useTreatmentLabels(clinicId);
  const { askConfirmation, ConfirmationDialog } = useConfirmationDialog();
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  const handleCreate = async ({
    TreatmentTypeDefinition,
    TreatmentUserID,
    ...treatment
  }: PatientTreatment) => {
    try {
      await createTreatment(patientId, treatment);
      trackFormSuccess('patient-treatment');
    } catch (error: unknown) {
      const errorMessage = isError(error) ? error.message : (error as string);

      Logger.captureException(error);
      toastError(errorMessage);
      trackFormError('patient-treatment', { error: errorMessage });
    }
  };

  const handleUpdate = async ({
    TreatmentTypeDefinition,
    TreatmentUserID,
    ...treatment
  }: PatientTreatment) => {
    try {
      await updateTreatment(patientId, TreatmentUserID, treatment);
      handleRemoveEditTreatment(TreatmentUserID);
    } catch (error: unknown) {
      const errorMessage = isError(error) ? error.message : (error as string);

      toastError(errorMessage);
    }
  };

  const handleDelete = async (treatment: PatientTreatment) => {
    try {
      await deleteTreatment(patientId, treatment);
    } catch (error: unknown) {
      const errorMessage = isError(error) ? error.message : (error as string);

      toastError(errorMessage);
    }
  };

  const handleAddNewTreatment = () => {
    const newTreatment = {
      // NOTE: Set high numbers as id, used locally to identify treatment rows. Can be improved ;)
      TreatmentUserID: parseInt(uniqueId(), 10) * 1000000,
      TreatmentTypeID: 0,
      StartDate: '',
      EndDate: undefined,
      TreatmentTypeDefinition: '',
      CustomTreatmentTypeName: '',
    };

    openDialog({
      title: intl.formatMessage(translations.addTreatment),
      contentProps: {
        treatment: newTreatment,
        clinicId,
        onSave: handleCreate,
        onCancel: () => {},
        treatmentsOffered,
      },
    });
  };

  const handleRemoveEditTreatment = (treatmentUserId: number) => {
    const arr = editTreatments.filter((id: number) => id !== treatmentUserId);

    setEditTreatments([...arr]);
  };

  const handleOnDelete = async (treatment: PatientTreatment) => {
    askConfirmation({
      title: intl.formatMessage(translations.treatmentDelete),
      confirmText: intl.formatMessage(globalMessages.delete),
      cancelText: intl.formatMessage(globalMessages.cancel),
      onConfirm: () => handleDelete(treatment),
    });
  };

  const hasTreatments = treatments.length > 0;

  if (isLoading) {
    return (
      <Card title={cardTitle}>
        <div
          style={{
            minHeight: '100px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <InlineLoader />
        </div>
      </Card>
    );
  }

  return (
    <>
      <ConfirmationDialog />
      <Card
        title={cardTitle}
        iconButtons={[
          {
            icon: <PlusIcon />,
            onClick: handleAddNewTreatment,
            tooltip: intl.formatMessage(translations.treatmentAddTooltip),
          },
        ]}
        variant={hasTreatments ? 'default' : 'danger-border'}
      >
        {hasTreatments ? (
          <>
            <div className={styles.treatments}>
              {treatments.map((treatment) => (
                <div className={styles.treatment} key={treatment.TreatmentUserID}>
                  <div className={styles.content}>
                    <div className={styles.title}>
                      {getTreatmentTypeLabel(treatment.TreatmentTypeID)}
                    </div>
                    <div className={styles.subtitle}>
                      {toLocalizedDate(treatment.StartDate, locale)}
                      {treatment.EndDate ? ` - ${toLocalizedDate(treatment.EndDate, locale)}` : ''}
                    </div>
                  </div>
                  {!readOnly && (
                    <div className={styles.action}>
                      <Tooltip
                        text={intl.formatMessage(translations.treatmentEditTooltip)}
                        placement="bottom"
                      >
                        <TrackedIconButton
                          icon={<PencilIcon />}
                          variant="icon"
                          size="xs"
                          trackLabel="edit-patient-treatment"
                          onClick={() =>
                            openDialog({
                              title: intl.formatMessage(translations.editTreatmentDialogTitle),
                              contentProps: {
                                treatment,
                                onSave: handleUpdate,
                                clinicId,
                                onCancel: () => {},
                                treatmentsOffered,
                              },
                            })
                          }
                        />
                      </Tooltip>
                      <Tooltip
                        text={intl.formatMessage(translations.treatmentDeleteTooltip)}
                        placement="bottom"
                      >
                        <TrackedIconButton
                          icon={<TrashCanIcon />}
                          variant="icon"
                          size="xs"
                          trackLabel="delete-patient-treatment"
                          onClick={() => handleOnDelete(treatment)}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <TrackedEmptyState
            title={intl.formatMessage({
              id: 'patient-treatments.empty-state.title',
              defaultMessage: 'Add a treatment to start tracking this patient',
            })}
            subtitle={intl.formatMessage({
              id: 'patient-treatments.empty-state.subtitle',
              defaultMessage: 'You must add a treatment to view this patient’s data.',
            })}
            icon={<FileIcon />}
            trackedLabel="no-patient-treatments-found"
          />
        )}
      </Card>
      <Dialog />
    </>
  );
}
