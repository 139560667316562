import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { TrackedPageHeader } from 'src/analytics/TrackedUI';
import { Seo } from 'src/components/Seo';
import { AllurionSearchbar } from 'src/components/ui/AllurionSearchbar';
import { parseClinicsData } from 'src/domain/Clinic';
import { useAppNavigate } from 'src/hooks/useAppNavigate';
import { useClinic } from 'src/hooks/useClinic';
import { useDebounceCallback } from 'src/hooks/useDebounceCallback';

import { ManageUsersTable } from '../ManageUsers/ManageUsersTable';
import { Container, InnerContainer, Spacer } from '../shared-page-elements';

export function ClinicProvidersPage() {
  const intl = useIntl();
  const { clinicId } = useParams();
  const { clinic } = useClinic(clinicId);
  const { toInviteProviderPage, toEditProviderPage, navigateAndScrollToTop } = useAppNavigate();
  const [search, setSearch] = useState('');

  const clinics = clinic ? parseClinicsData([clinic]) : [];

  const debouncedHandleSearchInput = useDebounceCallback(handleSearchInput, 1000);

  return (
    <>
      <Seo title="Clinic Members" />
      <TrackedPageHeader
        title={intl.formatMessage({
          id: 'clinic-providers.header',
          description: 'Clinic Providers page header',
          defaultMessage: 'Clinic members',
        })}
        button={{
          label: intl.formatMessage({
            id: 'clinic-providers.invite-btn',
            defaultMessage: 'Invite member',
          }),
          onClick() {
            toInviteProviderPage(clinicId!);
          },
          trackLabel: 'invite-provider',
        }}
      />
      <Container>
        <InnerContainer>
          <AllurionSearchbar
            placeholder={intl.formatMessage({
              id: 'clinics-members-page.provider-search',
              defaultMessage: 'Search by name, surname or email',
            })}
            onChange={debouncedHandleSearchInput}
          />
          <Spacer />
          <ManageUsersTable
            search={search}
            clinics={clinics}
            onRowClick={(providerId, options) => toEditProviderPage(clinicId!, providerId, options)}
            onResendInviteClick={(options) =>
              navigateAndScrollToTop('./resend-invitation', options)
            }
          />
        </InnerContainer>
      </Container>
    </>
  );

  function handleSearchInput(evt: React.ChangeEvent<HTMLInputElement>) {
    setSearch(evt.target.value);
  }
}
