import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { SelectClinicsTable } from 'src/components/SelectClinicsTable/SelectClinicsTable';
import { AllurionErrorIndicator } from 'src/components/ui/AllurionErrorIndicator';
import { AllurionRadioButtons } from 'src/components/ui/AllurionRadioButtons';
import { AllurionTextField } from 'src/components/ui/AllurionTextField';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { ParsedClinicData } from 'src/domain/Clinic';
import { ADMIN_ROLE_ID, COACH_ROLE_ID, STAFF_ROLE_ID } from 'src/domain/User';
import inviteUserMessages from 'src/messages/invite-user.messages';

import messages from './user-form-messages';
import { validate } from './user-form-validate';

type Props = {
  initialValues: {
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: { value: string; label: string };
    clinics?: ParsedClinicData[];
  };
  onSubmit: (values: {
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: { value: string; label: string };
    clinics?: ParsedClinicData[];
  }) => void;
  isLoading: boolean;
  isEditForm?: boolean;
  allClinics: ParsedClinicData[];
  providerClinics?: string[];
};

export function ProviderForm({
  initialValues,
  onSubmit,
  allClinics,
  providerClinics = [],
  isEditForm = false,
  isLoading = false,
}: Props) {
  const intl = useIntl();
  const radioRoleOpts = [
    {
      value: ADMIN_ROLE_ID,
      label: intl.formatMessage(messages.admin),
      id: 'admin',
    },
    {
      value: STAFF_ROLE_ID,
      label: intl.formatMessage(messages.staff),
      id: 'staff',
    },
  ];

  if (!isEditForm) {
    initialValues.role = undefined;
  }

  if (isLoading) {
    return <InlineLoader />;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate(isEditForm, intl)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, setFieldTouched, values, errors, touched }) => {
        const hasTouched = Object.keys(touched).length > 0;

        const handleSelectedClinics = (clinics: ParsedClinicData[]) => {
          setFieldValue('clinics', clinics);
          setFieldTouched('clinics', true);
        };
        const handleSelectRadioButton = ({ value }: { value: string }) => {
          const selectedOption = radioRoleOpts.filter(({ value: v }) => v === value);
          const label = selectedOption?.[0]?.label;

          setFieldTouched('role', true);
          setFieldValue('role', { value, label });
        };

        const defaultRole = values.role?.value;

        const editButtonLabel = isEditForm
          ? intl.formatMessage(messages.updateUser)
          : intl.formatMessage(inviteUserMessages.addButton);
        const buttonLabel =
          defaultRole === 'delete' ? intl.formatMessage(messages.deleteUser) : editButtonLabel;

        return (
          <Form onSubmit={handleSubmit}>
            <RowCollapse>
              <FieldWrapper>
                <AllurionTextField
                  name="firstName"
                  type="text"
                  placeholder={intl.formatMessage(messages.firstName)}
                  label={intl.formatMessage(messages.firstName)}
                />
              </FieldWrapper>
              <FieldWrapper>
                <AllurionTextField
                  name="lastName"
                  type="text"
                  placeholder={intl.formatMessage(messages.lastName)}
                  label={intl.formatMessage(messages.lastName)}
                />
              </FieldWrapper>
            </RowCollapse>
            <FieldWrapper>
              <AllurionTextField
                name="email"
                type="email"
                placeholder={intl.formatMessage(messages.email)}
                label={intl.formatMessage(messages.email)}
                disabled={isEditForm}
                value={values.email}
              />
            </FieldWrapper>
            <Label>{intl.formatMessage(messages.selectClinic)}</Label>
            <SelectClinicsTable
              onSelected={handleSelectedClinics}
              allClinics={allClinics}
              providerClinics={providerClinics}
            />
            <AllurionErrorIndicator
              show={touched.clinics as boolean}
              label={errors?.clinics as string}
            />

            <FieldWrapper>
              <span
                style={{
                  fontSize: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <span style={{ marginRight: 10 }}>{intl.formatMessage(messages.role)}</span>
              </span>
              <AllurionRadioButtons
                defaultValue={defaultRole}
                onSelect={handleSelectRadioButton}
                options={radioRoleOpts}
                name="role"
              />
            </FieldWrapper>
            {initialValues?.role?.value != ADMIN_ROLE_ID &&
              hasTouched &&
              values.role?.value == ADMIN_ROLE_ID && (
                <Error>
                  {intl.formatMessage(isEditForm ? messages.changeAdminUser : messages.adminUser)}
                </Error>
              )}
            {initialValues?.role?.value === ADMIN_ROLE_ID &&
              hasTouched &&
              isEditForm &&
              values.role?.value == STAFF_ROLE_ID && (
                <Error>{intl.formatMessage(messages.staffUser)}</Error>
              )}
            {initialValues?.role?.value != COACH_ROLE_ID &&
              hasTouched &&
              isEditForm &&
              values.role?.value == COACH_ROLE_ID && (
                <Error>{intl.formatMessage(messages.coachUser)}</Error>
              )}
            {isEditForm && values.role?.value === 'delete' && (
              <Error>{intl.formatMessage(messages.deleteUserDisclaimer)}</Error>
            )}
            <AllurionErrorIndicator show={touched.role as boolean} label={errors?.role as string} />
            <Controls>
              <TrackedButton type="submit" label={buttonLabel} trackLabel="sumit-provider-form" />
            </Controls>
          </Form>
        );
      }}
    </Formik>
  );
}

const Form = styled.form`
  max-width: 914px;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 20px;
  margin-bottom: 15px;
`;

const FieldWrapper = styled.div`
  width: 100%;
  ${({ theme }) => theme.media.min.mobile`
    width: 50%;
    &:not(:nth-of-type(2n)){
      padding-right: 38px;
    }
  `}
  color: ${({ theme }) => theme.colors.Primary};
`;

const RowCollapse = styled.div`
  display: flex;
  ${({ theme }) => theme.media.max.mobile`
    flex-direction: column;
  `}
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;
`;

const Error = styled.div`
  color: ${({ theme }) => theme.colors.Alert};
  font-size: 18px;
  line-height: 30px;
  max-width: 650px;
`;
