import Chart from 'chart.js/auto';
import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import 'chartjs-plugin-style';

import tooltips from './activity-tooltips';

Chart.defaults.font.family = "'GT-America-Standard-Regular', 'PT Sans', sans-serif";

const datasets = (color: string, data: { x: string; y: number }[]) => [
  {
    data,
    label: '',
    backgroundColor: color,
    barPercentage: 0.5,
    borderRadius: 4,
  },
];

type Props = {
  id: string;
  color: string;
  data: { x: string; y: number; date: Date }[];
  stepSize: number;
  ytickFormatter: (
    value: string | number,
    index: number,
    values: (string | number)[]
  ) => string | number;
  tooltipLabelFormatter: (
    tooltipItem: {
      parsed: { x: number; y: number };
    },
    data: string
  ) => string;
  custom?: (tooltip: string) => string;
};

export function ActivityBarChart({
  id,
  color,
  data,
  stepSize,
  ytickFormatter,
  tooltipLabelFormatter,
  custom,
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart>(null);
  const labels = [...data].sort((a, b) => a.date.getTime() - b.date.getTime()).map((d) => d.x);

  useEffect(() => {
    //
    // re-renders on width change to get correct styles
    //
    const ctx = canvasRef.current?.getContext('2d');

    if (chartRef.current) {
      chartRef.current?.destroy();
    }

    if (!ctx) {
      throw new Error('Canvas not found');
    }

    // @ts-ignore
    chartRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: datasets(color, data),
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        // @ts-ignore
        cornerRadius: 1,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 40,
            bottom: 0,
          },
        },
        scales: {
          x: {
            title: {
              display: false,
            },
            ticks: {
              display: true,
            },
            grid: {
              display: false,
            },
            border: {
              display: true,
            },
          },
          y: {
            title: {
              display: false,
            },
            beginAtZero: true,
            ticks: {
              display: true,
              padding: 5,
              font: {
                size: 10,
              },
              stepSize,
              callback: ytickFormatter as any,
            },
            grid: {
              display: true,
              color: '#CCD7D9',
              tickLength: 0,
            },
            border: {
              display: false,
              dash: [2, 3],
            },
          },
        },
        plugins: {
          tooltip: tooltips(tooltipLabelFormatter, custom),
          legend: {
            display: false,
          },
        },
      },
    });
  }, [color, custom, data, labels, stepSize, tooltipLabelFormatter, ytickFormatter]);

  return (
    <Container>
      <canvas id={id} ref={canvasRef} />
    </Container>
  );
}

const Container = styled.div`
  transition: height 0.3s;
  height: 200px;
  ${({ theme }) => theme.media.min.mobile`
    height: 300px;
    `}
  ${({ theme }) => theme.media.min.tablet`
    height: 400px;
  `}
  ${({ theme }) => theme.media.min.desktop`
    height: 250px;
  `}
`;

/*
 *   Rounded Rectangle Extension for Bar Charts and Horizontal Bar Charts
 *   Tested with Charts.js 2.7.0
 */
/* eslint-disable */
// @ts-ignore

/* eslint-disable */
