import { PageHeader } from '@allurion/ui';
import { isError } from '@allurion/utils';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useTrackEvent } from 'src/analytics/analytics';
import { createProviderUser } from 'src/api/UserApi';
import { Loader } from 'src/components/ui/Loader';
import { toastSuccess, toastError } from 'src/components/ui/toasts';
import { ParsedClinicData } from 'src/domain/Clinic';
import { STAFF_ROLE_ID } from 'src/domain/User';
import { ProviderForm } from 'src/forms/User/ProviderForm';
import { getUserLanguage } from 'src/helpers/locale';
import { useLocale } from 'src/hooks/useLocale';
import { Container, InnerContainer, Spacer } from 'src/pages/shared-page-elements';
import { Logger } from 'src/services/Logger';

import translations from '../ManageUsers.translations';

import { ExpirationWarning } from './ExpirationWarning';

type FormValues = {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: { value: string; label: string };
  clinics?: ParsedClinicData[];
};

type Props = {
  onCreate: (state: {
    formEmail: string;
    formFirstName: string;
    formLastName: string;
    clinicLabel: string;
    roleLabel: string;
  }) => void;
  goBack: () => void;
  clinics: ParsedClinicData[];
};

export function InviteProvider({ onCreate, goBack, clinics }: Props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const { trackFormSuccess, trackFormError } = useTrackEvent();

  const { locale } = useLocale();
  const lang = getUserLanguage(locale);

  const createUser = async (values: FormValues) => {
    setIsLoading(true);
    const selectedClinics = values.clinics?.map(({ id }) => id) || [];
    const clinicLabel = values.clinics?.map(({ label }) => label).join(', ');

    try {
      const newProvider = {
        email: values.email!,
        lang,
        firstname: values.firstName!,
        lastname: values.lastName!,
        role: values.role!.value,
        clinics: selectedClinics,
      };

      await createProviderUser(newProvider);
      setIsLoading(false);
      toastSuccess(intl.formatMessage(translations.userFormWrapperSuccessMessage));
      trackFormSuccess('invite-provider');
      onCreate({
        formEmail: newProvider.email,
        formFirstName: newProvider.firstname,
        formLastName: newProvider.lastname,
        clinicLabel: clinicLabel!,
        roleLabel: values.role!.label,
      });
    } catch (error) {
      const errorMessage = isError(error) ? error.message : error;

      Logger.captureException(error);
      setIsLoading(false);
      toastError(intl.formatMessage(translations.userFormWrapperDuplicateUserMessage));
      trackFormError('invite-provider', { error: errorMessage });
    }
  };

  const onSubmit = (values: FormValues) => {
    createUser(values);
  };

  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    role: {
      label: intl.formatMessage(translations.manageUsersStaff),
      value: STAFF_ROLE_ID,
    },
    clinics: [],
  };

  const hoursUntilExpires = 48;

  return (
    <>
      <PageHeader
        title={intl.formatMessage(translations.userFormWrapperInviteNewUser)}
        onNavButtonClick={goBack}
      />
      <Container>
        <InnerContainer>
          <Label>{intl.formatMessage(translations.userFormWrapperCreatePassword)}</Label>
          <ExpirationWarning hoursUntilExpires={hoursUntilExpires} />
          <Spacer />
          <ProviderForm
            isEditForm={false}
            isLoading={isLoading}
            initialValues={initialValues}
            onSubmit={onSubmit}
            allClinics={clinics}
          />

          <Loader isLoading={isLoading} />
        </InnerContainer>
      </Container>
    </>
  );
}

const Label = styled.label`
  display: inline-block;
  font-size: 18px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.Primary};
`;
