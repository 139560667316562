import { FormattedMessage } from 'react-intl';

import globalMessages from 'src/messages/global.messages';

export function validate({
  password,
  confirmPassword,
}: {
  password?: string;
  confirmPassword?: string;
}) {
  const errors: {
    password?: JSX.Element;
    confirmPassword?: JSX.Element;
  } = {};

  if (!password) {
    errors.password = <FormattedMessage id={globalMessages.required.id} />;
  } else if (password.length < 8) {
    errors.password = <FormattedMessage id={globalMessages.minChars.id} />;
  } else if (!/[a-z]/.test(password)) {
    errors.password = <FormattedMessage id={globalMessages.oneLower.id} />;
  } else if (!/[A-Z]/.test(password)) {
    errors.password = <FormattedMessage id={globalMessages.oneCapital.id} />;
  } else if (!/[0-9]/.test(password)) {
    errors.password = <FormattedMessage id={globalMessages.oneNumber.id} />;
  } else if (!/[!@#$%^&*()_+{}:;'"/\\]/.test(password)) {
    errors.password = <FormattedMessage id={globalMessages.oneSpecialChar.id} />;
  }

  if (!confirmPassword) {
    errors.confirmPassword = <FormattedMessage id={globalMessages.required.id} />;
  } else if (confirmPassword !== password) {
    errors.confirmPassword = <FormattedMessage id={globalMessages.passwordMustMatch.id} />;
  }

  return errors;
}
