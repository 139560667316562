import { isPatientAtRisk } from '@allurion/domain';
import { Card } from '@allurion/ui';
import { isNotNull } from '@allurion/utils';
import { useIntl } from 'react-intl';

import { EditStartingWeight } from 'src/components/WeightEntry/EditStartingWeight';
import { ClinicSettings } from 'src/domain/Clinic';
import { PatientOverview, PatientProfile } from 'src/domain/patient/Patients';
import { isManualWeightEntryEnabled } from 'src/domain/settings';
import { isRiskAnalyisEnabled } from 'src/domain/settings';
import { convertWeight } from 'src/helpers/convertions';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import translations from 'src/messages/translations';

import { SuccessPredictorCard } from './SuccessPredictorCard';

import styles from './PatientProgressOverviewCard.module.scss';

type Props = {
  patientId: string;
  patient: PatientProfile;
  data?: PatientOverview;
  settings: ClinicSettings;
  reloadData: () => void;
};

export function PatientProgressOverviewCard({
  data,
  settings,
  patient,
  patientId,
  reloadData,
}: Props) {
  const intl = useIntl();
  const { unitsPreference } = useCurrentUser();
  const {
    starting_weight,
    current_weight,
    weight_loss_since_placement,
    perc_weight_loss_since_placement,
    starting_bmi,
    current_bmi,
    weeks_in_program,
    weeks_weighed_in,
  } = data ?? {};
  const startingWeight = convertWeight(starting_weight, unitsPreference);
  const currentWeight = convertWeight(current_weight, unitsPreference);
  const totalWeightLoss = convertWeight(weight_loss_since_placement, unitsPreference);
  const isAtRiskEnabled = isRiskAnalyisEnabled(settings);
  const isAtRisk = isPatientAtRisk(patient.at_risk_score, patient.at_risk_display);
  const shouldRenderSuccessPreditorCard = isAtRiskEnabled && isNotNull(isAtRisk);
  const units = unitsPreference;

  return (
    <Card title={intl.formatMessage(translations.progressOverview)}>
      {shouldRenderSuccessPreditorCard && (
        <SuccessPredictorCard
          isAtRisk={isAtRisk}
          at_risk_display={patient.at_risk_display}
          at_risk_score={patient.at_risk_score}
        />
      )}

      <div className={styles.metrics}>
        <Metric
          label={intl.formatMessage(translations.clinicOverviewStartingWeight)}
          value={starting_weight ? `${startingWeight} ${units}` : '-'}
          action={
            isManualWeightEntryEnabled(settings) && (
              <EditStartingWeight patientId={patientId} afterSave={reloadData} />
            )
          }
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewCurrentWeight)}
          value={`${currentWeight} ${units}`}
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewTotalWeightLoss)}
          value={`${totalWeightLoss} ${units}`}
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewWeightLossPercentage)}
          value={`${perc_weight_loss_since_placement?.toFixed(1) || 0} %`}
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewStartingBmi)}
          value={`${starting_bmi?.toFixed(1) || 0}`}
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewCurrentBmi)}
          value={`${current_bmi?.toFixed(1) || 0}`}
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewWeeksInProgram)}
          value={`${weeks_in_program?.toFixed(0) || 0}`}
        />
        <Metric
          label={intl.formatMessage(translations.clinicOverviewWeeksOneWeighIn)}
          value={`${weeks_weighed_in?.toFixed(0) || 0}`}
        />
      </div>
    </Card>
  );
}

function Metric({
  label,
  value,
  action,
}: {
  label: string;
  value: string;
  action?: React.ReactNode;
}) {
  return (
    <div className={styles.metric}>
      <div className={styles.metricContent}>
        <span className={styles.metricLabel}>{label}</span>
        <span className={styles.metricValue}>{value}</span>
      </div>
      {action && <span className={styles.metricAction}>{action}</span>}
    </div>
  );
}
