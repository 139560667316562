import { Navigate, useLocation, createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ADMIN_ROLE_ID, STAFF_ROLE_ID } from 'src/domain/User';
import { useCurrentUser } from 'src/hooks/useCurrentUser';
import { ClinicPage } from 'src/pages/ClinicPage/ClinicPage';
import ClinicSettingsPage from 'src/pages/ClinicPage/ClinicSettingsPage/ClinicSettingsPage';
import CreatePasswordPage from 'src/pages/CreatePasswordPage';
import { HomePage } from 'src/pages/HomePage';
import InvitePatientPage from 'src/pages/InvitePatientPage/InvitePatientPage';
import LoginPage from 'src/pages/LoginPage';
import ManagePatientsPage from 'src/pages/ManagePatientsPage/ManagePatientsPage';
import NotFoundPage from 'src/pages/NotFoundPage';
import PatientPage from 'src/pages/PatientPage/PatientPage';
import ProfilePage from 'src/pages/ProfilePage';
import ResetPassword from 'src/pages/ResetPasswordPage';
import TermsAndConditionsPage from 'src/pages/TermsAndConditionsPage';
import { termsConditionsRoute } from 'src/utils/appRoutes';

import { AuthenticatedLayout, Layout } from './components/Layout';
import { ResetPasswordForm } from './forms/ResetPassword/ResetPasswordForm';
import { ValidateEmailCodeForm } from './forms/ValidateEmailCode/ValidateEmailCode';
import { ClinicAnalyticsPage } from './pages/ClinicPage/ClinicAnalyticsPage';
import { ClinicPatientsPage } from './pages/ClinicPage/ClinicPatientsPage/ClinicPatientsPage';
import { ClinicProvidersPage } from './pages/ClinicPage/ClinicProvidersPage';
import { EditProviderPage } from './pages/ClinicPage/EditProviderPage';
import { InviteProviderPage } from './pages/ClinicPage/InviteProviderPage';
import { ResendInvitationPage } from './pages/ManageUsers/Form/ResendInvitationPage';
import MessageNotificationsPage from './pages/MessageNotificationsPage/MessageNotificationsPage';

declare global {
  interface Window {
    updateTokenInterval: number;
  }
}

export function AppRoutes() {
  const routes = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/create-password/:clientId/:username/:confirmationCode',
          element: <CreatePasswordPage />,
        },
        {
          path: '/reset-password/',
          element: <ResetPassword />,
          children: [
            {
              path: '',
              element: <ResetPasswordForm />,
            },
            {
              path: 'validate-email',
              element: <ValidateEmailCodeForm />,
            },
          ],
        },
        {
          path: '/',
          element: (
            <RequireAuth>
              <AuthenticatedLayout />
            </RequireAuth>
          ),
          children: [
            {
              path: '',
              element: <HomePage />,
            },

            {
              path: '/profile',
              element: <ProfilePage />,
            },
            {
              path: '/clinic',
              element: (
                <RequireAuth allowedRoles={[ADMIN_ROLE_ID, STAFF_ROLE_ID]}>
                  <ClinicPage />
                </RequireAuth>
              ),
            },
            {
              path: '/clinic/:clinicId/',
              element: (
                <RequireAuth allowedRoles={[ADMIN_ROLE_ID, STAFF_ROLE_ID]}>
                  <ClinicPage />
                </RequireAuth>
              ),
              children: [
                {
                  path: 'patients',
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID, STAFF_ROLE_ID]}>
                      <ClinicPatientsPage />
                    </RequireAuth>
                  ),
                },
                {
                  path: 'patient/:patientId',
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID, STAFF_ROLE_ID]}>
                      <PatientPage />
                    </RequireAuth>
                  ),
                },
                {
                  path: 'invite',
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID]}>
                      <InvitePatientPage />
                    </RequireAuth>
                  ),
                },
                {
                  path: 'organize-patients',
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID]}>
                      <ManagePatientsPage />
                    </RequireAuth>
                  ),
                },
                {
                  path: 'analytics',
                  element: <ClinicAnalyticsPage />,
                },
                {
                  path: 'members',
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID]}>
                      <ClinicProvidersPage />
                    </RequireAuth>
                  ),
                },
                {
                  path: 'members/resend-invitation',
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID]}>
                      <ResendInvitationPage />
                    </RequireAuth>
                  ),
                },
                {
                  path: 'members/:providerId',
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID]}>
                      <EditProviderPage />
                    </RequireAuth>
                  ),
                },
                {
                  path: 'invite-member',
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID]}>
                      <InviteProviderPage />
                    </RequireAuth>
                  ),
                },
                {
                  path: `settings`,
                  element: (
                    <RequireAuth allowedRoles={[ADMIN_ROLE_ID]}>
                      <ClinicSettingsPage />
                    </RequireAuth>
                  ),
                },
              ],
            },
            {
              path: termsConditionsRoute,
              element: <TermsAndConditionsPage />,
            },
            {
              path: '/message-notifications',
              element: (
                <RequireAuth allowedRoles={[ADMIN_ROLE_ID, STAFF_ROLE_ID]}>
                  <MessageNotificationsPage />
                </RequireAuth>
              ),
            },
            {
              path: '*',
              element: <NotFoundPage />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={routes} />;
}

function RequireAuth({
  children,
  allowedRoles = [],
}: {
  children: JSX.Element;
  allowedRoles?: string[];
}) {
  const { isAuthenticated, userRole } = useCurrentUser();
  const location = useLocation();

  let hasAccess = isAuthenticated;

  if (hasAccess && allowedRoles.length > 0) {
    hasAccess = allowedRoles.some((roleId) => userRole === roleId);
  }

  if (!hasAccess) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const redirectTo = encodeURIComponent(location.pathname + location.search);

    return <Navigate to={`/login?redirectTo=${redirectTo}`} replace />;
  }

  return children;
}
