import { isError, isNotNull, isUndefined } from '@allurion/utils';
import { useRef, useState, useEffect } from 'react';
import {
  createLocalAudioTrack,
  createLocalVideoTrack,
  Track,
  AudioTrack,
  VideoTrack,
} from 'twilio-video';

import { VIDEO_SIZE } from 'src/utils/constants';

import { SelectedDevice } from './video-call-types';

const { width, height } = VIDEO_SIZE;

type Props = {
  participant: any;
  muteAudio?: boolean;
  stopVideo?: boolean;
  device?: SelectedDevice;
};

export function VideoCallParticipant({ participant, muteAudio, stopVideo, device }: Props) {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [videoTracks, setVideoTracks] = useState<VideoTrack[] | []>([]);
  const [audioTracks, setAudioTracks] = useState<AudioTrack[] | []>([]);

  useEffect(() => {
    const trackSubscribed = (track: VideoTrack | AudioTrack) => {
      if (track?.kind === 'video') {
        setVideoTracks((tracks) => [...tracks, track]);
      } else {
        setAudioTracks((tracks) => [...tracks, track]);
      }
    };

    const trackUnsubscribed = (track: VideoTrack | AudioTrack) => {
      if (track?.kind === 'video') {
        setVideoTracks((tracks) => tracks.filter((t) => t !== track));
      } else {
        setAudioTracks((tracks) => tracks.filter((t) => t !== track));
      }
    };

    const pubsToTrack = (tracks: Track[]) =>
      tracks &&
      Array.from(tracks.values())
        .map((pub: any) => pub.track)
        .filter(isNotNull);

    setVideoTracks(pubsToTrack(participant?.videoTracks));
    setAudioTracks(pubsToTrack(participant?.audioTracks));

    participant?.on('trackSubscribed', trackSubscribed);
    participant?.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      participant?.off('trackSubscribed', trackSubscribed);
      participant?.off('trackUnsubscribed', trackUnsubscribed);
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks && videoTracks[0];
    const audioTrack = audioTracks && audioTracks[0];

    videoTrack?.attach(videoRef.current as any);
    audioTrack?.attach(audioRef.current as any);

    return () => {
      videoTrack?.detach();
      audioTrack?.detach();
    };
  }, [videoTracks, audioTracks]);

  useEffect(() => {
    const videoTrack = videoTracks && (videoTracks[0] as any);

    if (isUndefined(stopVideo)) return;
    if (stopVideo) {
      videoTrack?.disable();
    } else {
      videoTrack?.enable();
    }
  }, [stopVideo, videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks && (audioTracks[0] as any);

    if (isUndefined(muteAudio)) return;
    if (muteAudio) {
      audioTrack?.disable();
    } else {
      audioTrack?.enable();
    }
  }, [muteAudio, audioTracks]);

  useEffect(() => {
    const videoTrack = videoTracks && videoTracks[0];
    const audioTrack = audioTracks && audioTracks[0];

    const updateMicrophone = async () => {
      const localAudioTrack = await createLocalAudioTrack({ deviceId: device?.id });

      audioTrack?.detach();
      participant?.unpublishTrack(audioTrack);
      localAudioTrack?.attach(audioRef.current as any);
      participant?.publishTrack(localAudioTrack);
      setAudioTracks([localAudioTrack]);
    };

    const updateCamera = async () => {
      const localVideoTrack = await createLocalVideoTrack({ deviceId: device?.id, width, height });

      videoTrack?.detach();
      participant?.unpublishTrack(videoTrack);
      localVideoTrack?.attach(videoRef.current as any);
      participant?.publishTrack(localVideoTrack);
      setVideoTracks([localVideoTrack]);
    };

    if (device?.kind === 'videoinput') {
      try {
        updateCamera();
      } catch (error: unknown) {
        const errorMessage = isError(error) ? error.message : (error as string);

        window.alert(errorMessage);
      }
    } else if (device?.kind === 'audioinput') {
      try {
        updateMicrophone();
      } catch (error: unknown) {
        const errorMessage = isError(error) ? error.message : (error as string);

        window.alert(errorMessage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, participant]);

  return (
    <>
      <video ref={videoRef} autoPlay />
      <audio ref={audioRef} autoPlay />
    </>
  );
}
