import { useField } from 'formik';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { AllurionErrorIndicator } from 'src/components/ui/AllurionErrorIndicator';
import { AllurionSelect } from 'src/components/ui/AllurionSelect';
import { useClinicProviders } from 'src/hooks/useClinicProviders';
import { ProviderSelectorOption } from 'src/pages/InvitePatientPage/AddPatient.types';

type Props = {
  clinicId?: string;
  name: string;
  label: string;
  selected?: ProviderSelectorOption[];
  onChange: (value: ProviderSelectorOption[]) => void;
};

export function ProvidersSelector({ onChange, clinicId, name, label, selected, ...props }: Props) {
  const intl = useIntl();
  const [, meta, helpers] = useField({ name, ...props });
  const { error, touched } = meta || {};
  const { providers } = useClinicProviders(clinicId);

  const options: ProviderSelectorOption[] = providers.map((provider) => ({
    value: JSON.stringify({ providerID: provider.ProviderID }),
    label: `${provider.FirstName} ${provider.LastName}`,
  }));

  const handleSelectAll = () => {
    handleOnChange(options);
  };

  const handleOnChange = (newSelection: ProviderSelectorOption[] | ProviderSelectorOption) => {
    helpers.setError(undefined);
    if (onChange) {
      onChange(Array.isArray(newSelection) ? newSelection : [newSelection]);
    }
  };

  return (
    <Container>
      <Header>
        <label>{label}</label>
        <SelectAll onClick={handleSelectAll}>
          {intl.formatMessage({ id: 'providers-selector', defaultMessage: 'Select all' })}
        </SelectAll>
      </Header>

      <AllurionSelect
        value={selected}
        options={options}
        noOptionsMessage={() =>
          intl.formatMessage({ id: 'providers-selector.no-options', defaultMessage: 'No options' })
        }
        name="providers"
        onChange={handleOnChange}
        isMulti
        {...props}
      />
      <AllurionErrorIndicator label={error} show={touched} data-cy="errTreatmentSelector" />
    </Container>
  );
}

const Header = styled.div`
  font-size: 16px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  .react-select__indicators {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .react-select__indicator {
    color: ${({ theme }) => theme.colors.Primary};
    padding: 0;
    margin: 0 5px 0 0;
    cursor: pointer;
  }

  .react-select__input-container {
    padding-right: 50px;
    width: 100%;
    order: -1;
    input {
      position: relative;
      padding: 40px;
      top: -5px;
      width: auto !important;
    }
    input::placeholder {
      color: ${({ theme }) => theme.colors.SoftGrey};
      font-family: 'GT-America-Standard-Light', sans-serif;
    }
  }
  .react-select__menu {
    top: 36px;
  }
`;
const SelectAll = styled.a`
  color: #0c33d0;
  cursor: pointer;
  text-decoration: underline;
`;
