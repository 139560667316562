import styled from 'styled-components';

type Props = {
  data: {
    label: string | JSX.Element;
    value: string | number;
  };
};

export function StatCard({ data }: Props) {
  return (
    <Container>
      <Label>{data.label}</Label>
      <Value>{data.value}</Value>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  color: #111836;
  background-color: #fff;
  width: 150px;
  padding-left: 5px;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-right: 5px;
  margin-right: 18px;
  ${({ theme }) => theme.media.min.tablet`
    min-width: 180px;
  `}
`;

const Label = styled.p`
  /* font-weight: 700; */
  font-weight: bolder;
  font-size: 12px;
  white-space: normal;
  ${({ theme }) => theme.media.min.tablet`
    font-size: 15px;
  `}
`;

const Value = styled.div`
  color: ${({ theme }) => theme.colors.DarkBlue};
  /* font-weight: 700; */
  font-weight: bolder;
  font-size: 16px;
  line-height: 22px;
  margin-top: 7px;
  ${({ theme }) => theme.media.min.tablet`
    font-size: 22px;
  `}
`;
