import { useIntl } from 'react-intl';

import { TrackedButton } from 'src/analytics/TrackedUI';
import { ActivityBarChart } from 'src/components/ActivityBarChart/ActivityBarChart';
import { InlineLoader } from 'src/components/ui/InlineLoader';
import { ActivityChartData, ActivityListItem } from 'src/hooks/usePatientActivityData';
import globalMessages from 'src/messages/global.messages';

import styles from './ActivityCard.module.scss';

export interface GraphCardProps {
  graphId: string;
  graphBarColor: string;
  graphData: ActivityChartData;
  stepSize: number;
  ytickFormatter: (
    value: string | number,
    index: number,
    values: (string | number)[]
  ) => string | number;
  tooltipLabelFormatter: (
    tooltipItem: {
      parsed: { x: number; y: number };
    },
    data: string
  ) => string;
}

interface Props extends GraphCardProps {
  label: string;
  valueLabel: string;
  hasMiddleValue: boolean;
  data: Array<ActivityListItem>;
  fetchMoreData: () => void;
  isLoading: boolean;
}

export function ActivityCardBody({
  graphId,
  graphBarColor,
  graphData,
  stepSize,
  ytickFormatter,
  tooltipLabelFormatter,
  label,
  valueLabel,
  data,
  fetchMoreData,
  isLoading,
}: Props) {
  const intl = useIntl();

  return (
    <>
      <div className={styles.chart}>
        <ActivityBarChart
          id={graphId}
          color={graphBarColor}
          data={graphData}
          stepSize={stepSize}
          ytickFormatter={ytickFormatter}
          tooltipLabelFormatter={tooltipLabelFormatter}
        />
      </div>
      <div className={styles.table}>
        <div className={styles.table__header}>
          <div className={styles.table__header__item}>
            {intl.formatMessage({ id: globalMessages.date.id })}
          </div>
          {label ? <div className={styles.table__header__item}>{label}</div> : null}
          <div className={styles.table__header__item}>{valueLabel}</div>
        </div>
        <ul className={styles.table__content}>
          {data.map((d) => (
            <li
              className={styles.table__content__row}
              key={`${d.formattedDate}-${d.description || ''}`}
            >
              <span>{d.formattedDate}</span>
              {label ? <span>{d?.description}</span> : null}
              <span>{d.value}</span>
            </li>
          ))}
        </ul>
      </div>
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <InlineLoader />
        </div>
      ) : (
        <TrackedButton
          onClick={() => fetchMoreData?.()}
          variant="secondary"
          label={intl.formatMessage({
            id: 'activity-card.show-more',
            defaultMessage: 'Show more',
          })}
          trackLabel="show-more-link"
          style={{ width: '100%' }}
          size="xs"
        />
      )}
    </>
  );
}
