import { ComponentProps } from 'react';
import Select from 'react-select';

import { colors } from 'src/styles/theme/colors';

type Props<T> = Partial<ComponentProps<typeof Select>> & {
  options: T[];
  defaultValue?: T;
  dark?: boolean;
  isDisabled?: boolean;
  onChange?: (value: T) => void;
};

export function StyledSelect<T>({ options, defaultValue, isDisabled, ...props }: Props<T>) {
  return (
    <Select
      {...props}
      defaultValue={defaultValue}
      options={options}
      isDisabled={isDisabled}
      styles={{
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: (styles: object) => ({
          ...styles,
          color: colors.Primary,
        }),
        input: (styles: object) => ({ ...styles, outline: 'none' }),
        placeholder: (styles: object) => ({
          ...styles,
          color: 'grey',
          fontSize: '13px',
          fontFamily: 'GT-America-Standard-Light',
        }),
        singleValue: (styles: object) => ({
          ...styles,
          color: colors.Primary,
          fontSize: '13px',
          fontFamily: 'GT-America-Standard-Light',
          fontWeight: 100,
        }),
        control: (styles: object) => ({
          ...styles,
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          paddingLeft: '16px',
          paddingRight: '16px',
          borderRadius: '30px',
          border: 'none',
          boxShadow: 'none',
          outline: 'none',
          height: '40px',
        }),
        option: (
          styles: object,
          {
            isFocused,
            isSelected,
          }: {
            isFocused: boolean;
            isSelected: boolean;
          }
        ) => {
          const allStyles = {
            ...styles,
            fontFamily: 'GT-America-Standard-Regular',
          };

          // uses transparent menu bg color unless selected
          if (isSelected) {
            return {
              ...allStyles,
              backgroundColor: colors.GreyBorder,
              color: '#000',
            };
          }
          if (isFocused) {
            return {
              ...allStyles,
              backgroundColor: 'rgba(0,0,0,0.1)',
            };
          }

          return {
            ...allStyles,
            backgroundColor: 'transparent',
          };
        },
      }}
    />
  );
}
